import { Injectable } from '@angular/core';
import { getAnalytics, logEvent } from '@angular/fire/analytics';
import { Firestore, Timestamp, Unsubscribe, collection, doc, getDoc, onSnapshot, query, where } from '@angular/fire/firestore';
import { AdminDashboardService } from './admin-dashboard.service';
import { LandingService } from './LandingAndBidder.service';
import { firstValueFrom } from 'rxjs';
import { ServerDataFetchService } from './server-data-fetch.service';
import { ApplicationConstants } from '../util/ApplicationConstants';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { UserAuctionRegistrationDto } from '../models/user/UserAuctionRegistrationDto';
import { UserService } from './user.service';
import { AuctionAggregateDto as AuctionsAggregateDto } from '../models/user/AuctionAggregateDto';
import { AdminSourcingEventsDataHolderService } from './AdminSourcingEventsDataHolder.service ';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { OnboardingService } from './onboarding.service';
import { Store } from '@ngrx/store';
import { selectUserUiDto } from '../state-management/session.features';


@Injectable({
  providedIn: 'root'
})
export class ApplicationListenerService {

  collectionAuctions: string = "AUCTIONS";
  collectionAuctionsAggregate: string = "AUCTIONS_AGGREGATE";
  collectionOrganization: string = "ORGANIZATION";
  collectionUserAuctionRegistration: string = "USER_AUCTION_REGISTRATION";
  collectionDeploymentConfiguration: string = "DEPLOYMENT_CONFIGURATION";

  organizationUnsubscribe: Unsubscribe | undefined;
  allUserAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  myAuctionRegistrationsUnsubscribe: Unsubscribe | undefined;
  lastFloorBidMessagesUnsubscribe: Unsubscribe | undefined;

  lastFetchTimestamp = Timestamp.now();

  constructor(
    private firestore: Firestore,
    private adminService: AdminDashboardService,
    private landingService: LandingService,
    private onboardingService: OnboardingService,
    private serverDataFetchService: ServerDataFetchService,
    private store: Store
  ) { }

  // This is the first method to be called on application startup.
  async loadApplicationInitialData() {

    await this.getOrganizationFromServerSync();
    await this.loadDeploymentConfiguration();
    await this.loadTimezoneDataFromServerSync();
    await this.loadCategoriesDataFromServerSync();
  }

  async loadApplicationDataAfterLogin() {
    this.attachFirestoreAuctionAggregateChangeListener();
    this.attachFirestoreOrganizationChangeListener();
  }

  loadAndListenBidderRegistrationDataOfAuctionHouse() {
    this.loadAllUserAuctionRegistrationsOfAuctionHouseSync();
    this.listenAllUserAuctionRegistrationsOfAuctionHouse();
  }

  async loadDeploymentConfiguration() {
    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getDeploymentConfiguration());

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        this.adminService.updateDeploymentConfiguration(apiResponseDto.data!);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getOrganizationFromServerSync() {
    this.adminService.setOrganizationDataLoaded(false);
    try {
      let apiResponseDto = await firstValueFrom(this.onboardingService.fetchLandingOrganizationUiDto());

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let organizationUiDto = apiResponseDto.data as OrganizationWrapperUiDto;

        this.onboardingService.updateOrganizationUiDto(organizationUiDto);
        this.adminService.setOrganizationDataLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  attachFirestoreOrganizationChangeListener() {
    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let organizationRef = collection(this.firestore, this.collectionOrganization);
      let querySnapshot = query(organizationRef,
        where('orgCode', '==', orgCode),
        where('active', '==', true),
        where('updateTimeUtc', '>', this.lastFetchTimestamp));

      this.organizationUnsubscribe = onSnapshot(querySnapshot, documentSnapshots => {
        let organizations = documentSnapshots.docChanges().map(change => change.doc.data());

        if (organizations && organizations.length > 0) {
          let organizationUiDto = organizations[0] as OrganizationWrapperUiDto;
          this.onboardingService.updateOrganizationUiDto(organizationUiDto);
        }
      });
    }
  }

  /**
   *
   * @param lastFetchTimestamp
   */
  // attachFirestoreAuctionChangeListener() {
  //   let domainName = ApplicationUtils.getSubDomainName();
  //   if (domainName) {
  //     let auctionRef = collection(this.firestore, this.collectionAuctions);
  //     let auctionQuery = query(auctionRef, where('domainName', '==', domainName), where('updateTimeUtc', '>', this.lastFetchTimestamp));

  //     onSnapshot(auctionQuery, documentSnapshots => {
  //       let updatedAuctionDtos = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionEntityDto);

  //       // update wrraper dto in landing service after 2 secs
  //       setTimeout( () => {
  //         let auctionIdsSet = updatedAuctionDtos.map(item => item.auctionId!);
  //         if (auctionIdsSet.length > 0) {
  //           // this.getAuctionCardWrapperDtoSelected(auctionIdsSet);
  //         }
  //       }, 2000)
  //     });
  //   }
  // }

  /**
   *
   * @param lastFetchTimestamp
   */
  attachFirestoreAuctionAggregateChangeListener() {
    let orgCode = this.onboardingService.getOrgCode();
    if (orgCode) {
      let auctionRef = collection(this.firestore, this.collectionAuctionsAggregate);
      let auctionQuery = query(auctionRef, where('orgCode', '==', orgCode), where('updateTimeUtc', '>', this.lastFetchTimestamp));

      onSnapshot(auctionQuery, documentSnapshots => {
        let updatedAuctionAggregateDtos = documentSnapshots.docChanges().map(change => change.doc.data() as AuctionsAggregateDto);

        // update auction aggregate data
        //this.landingAndBidderService.updateAuctionAggregateChanges(updatedAuctionAggregateDtos);

      });
    }
  }

  /**
* Fetch the master data from server
* This method must be call ones
*/
  async loadTimezoneDataFromServerSync() {
    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getTimezonesDataFromServer());

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let timezones = apiResponseDto.data as any;

        this.adminService.updateTimezonesData(timezones);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /**
* Fetch the master data from server
* This method must be call ones
*/
  async loadCategoriesDataFromServerSync() {
    this.adminService.setMasterDataLoaded(false);

    try {
      let apiResponseDto = await firstValueFrom(this.serverDataFetchService.getCategoriesDataFromServer());

      if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
        let categories = apiResponseDto.data as any;

        this.adminService.updateCategoriesData(categories);
        this.landingService.updateMasterData(categories);
        this.adminService.setMasterDataLoaded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }


  /**
 * Get the AuctionCardWrapperDtos for a given auctionIds.
 * This method is called when client receive firestore update event
 * @param auctionIds
 */
  // private getAuctionCardWrapperDtoSelected(auctionIds: string[]) {
  //   if (auctionIds && auctionIds.length > 0) {
  //     console.log("updating auctionIds : " + auctionIds.length);
  //     this.serverDataFetchService.getAuctionCardWrapperDtosByIds(auctionIds).subscribe({
  //       next: (apiResponseDto: ServerAPIResponseDto) => {
  //         if (apiResponseDto && apiResponseDto.code == AuctionExtConstant.SUCCESS_CODE) {
  //           let updatedAuctionCardWrapperDtos = apiResponseDto.data as AuctionCardWrapperDto[];
  //           console.log("Updated AuctionCardWrapperDto received " + updatedAuctionCardWrapperDtos?.length)
  //           if (updatedAuctionCardWrapperDtos) {
  //             this.adminSourcingEventsDataHolderService.updateSourcingEventsWithAggregationData(updatedAuctionCardWrapperDtos);
  //           }
  //         }
  //       },
  //       error: (error) => {
  //         console.error(error);
  //       }
  //     })
  //   }
  // }

  loadAllUserAuctionRegistrationsOfAuctionHouseSync() {
    this.serverDataFetchService.getAllUserAuctionRegistrationsOfAuctionHouse().subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let userAuctionRegistrations = apiResponseDto.data as UserAuctionRegistrationDto[];
          this.adminService.updateAllUserAuctionRegistrationsList(userAuctionRegistrations);
        }
      },
      error: (error) => {
        console.error(error);
      }
    })
  }

  async listenAllUserAuctionRegistrationsOfAuctionHouse() {
    logEvent(getAnalytics(), 'listenAllUserAuctionRegistrationsOfAuctionHouse');

    let userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
    if (userUiDto) {
      let collectionRef = collection(this.firestore, this.collectionUserAuctionRegistration);
      const registrationQuery = query(collectionRef, where('orgCode', '==', userUiDto?.orgCode), where('updateTimeUtc', '>', this.lastFetchTimestamp));

      this.allUserAuctionRegistrationsUnsubscribe = onSnapshot(registrationQuery, (querySnapshot) => {
        let registrations = querySnapshot.docs.map(item => item.data());

        logEvent(getAnalytics(), 'listenAllUserAuctionRegistrationsOfAuctionHouse Data Received size : ' + registrations.length);
        console.log("listenAllUserAuctionRegistrationsOfAuctionHouse Data Received size: " + registrations.length);

        this.adminService.updateAllUserAuctionRegistrationsList(registrations);
      })
    }
  }


}
