import { CountryCodeDto } from "./CountryCodeDto";
import { FileInfoDto } from "./FileInfoDto";
import { OrgLanguageDto } from "./OrgLanguageDto";
import { PrivacyAndTermsOfUseDto } from "./PrivacyAndTermsOfUseDto";
import { UserInfoUiDto } from "./user/UserInfoUiDto";
import { UserUiDto } from "./user/UserUiDto";

export class OrganizationWrapperUiDto {
  name?: string;
  corporateUrl?: string;
  orgCode?: string;
  description?: string;
  adminDomainName?: string;
  bidderDomainName?: string;
  logo?: FileInfoDto;
  active?: boolean;
  bannerImage?: FileInfoDto;
  supportEmail?: string;
  phoneNo?: string;
  countryCode?: CountryCodeDto;
  primaryColor?: string;
  secondaryColor?: string;
  privacyAndTermsOfUseList?: Array<PrivacyAndTermsOfUseDto>;
  currencyCodes?: Array<string>;
  orgLanguageDtos?: Array<OrgLanguageDto>;
  demoAccount?: boolean
  theme?: string;
  version?: number;
  itManagerList?: UserInfoUiDto[];

  categories: string[] = [];
}