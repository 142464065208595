import { Component } from '@angular/core';

@Component({
  selector: 'app-superadmin-category',
  templateUrl: './superadmin-category.component.html',
  styleUrls: ['./superadmin-category.component.sass']
})
export class SuperadminCategoryComponent {

}
