<div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'DASHBOARD_PAGE'">
    <span class="c-pointer">Home</span>
    <span class="mx-2">|</span>
    <span>Dashboard</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'SOURCING_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Sourcing</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'AUCTION_GRAPH_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Graph Statics</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'AUCTION_ANALYTICS_DASHBOARD_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Auction Analytics</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'PROJECTS_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Projects</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'BIDDER_NETWORK_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Bidder Network</span>
  </div>
  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'IN_HOUSE_USER_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>In House Users</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'AUCTION_CREATOR_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('SOURCING_PAGE')">Sourcing</span>
    <span class="mx-2">|</span>
    <span>Auction</span>
    <span class="mx-2">|</span>
    <span>#{{selectedAuction?.sequenceNo}} {{selectedAuction?.auctionName}}</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'RFX_CREATOR_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('SOURCING_PAGE')">Sourcing</span>
    <span class="mx-2">|</span>
    <span>{{selectedRfx?.rfxType}}</span>
    <span class="mx-2">|</span>
    <span>#{{selectedRfx?.sequenceNo}} {{selectedRfx?.rfxTitle}}</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'ORGANIZATION_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToSuperAdminPage()">Home</span>
    <span class="mx-2">|</span>
    <span>Dashboard</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'SUPER_ADMIN_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToSuperAdminPage()">Home</span>
    <span class="mx-2">|</span>
    <span>Admin</span>
  </div>
  <!-- <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'ADMIN_CATALOGUE_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToSuperAdminPage()">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToCatalogueL1()" >Catalogue</span>
    <span class="mx-2" *ngIf="catalogueL1">|</span>
    <span *ngIf="catalogueL1" >{{catalogueL1}}</span>
    <span class="mx-2" *ngIf="catalogueL2">|</span>
    <span *ngIf="catalogueL2">{{catalogueL2}}</span>
  </div> -->

  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'ADMIN_CATALOGUE_PAGE' && !selectedCatalogueL1">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Catalogue</span>
  </div>

  <div class=" p-2 my-2 medium-font" *ngIf="pageName == 'ADMIN_CATALOGUE_PAGE' && selectedCatalogueL1">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('ADMIN_CATALOGUE_PAGE')"
      *ngIf="selectedCatalogueL1">
      Catalogue
    </span>
    <span class="mx-2">|</span>
    <span>{{selectedCatalogueL1.name}}</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'ADMIN_LINE_CATALOGUE_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('ADMIN_CATALOGUE_PAGE')">Catalogue</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToCatalogueL1()">{{selectedCatalogueL1?.name}}</span>
    <span class="mx-2">|</span>
    <span>{{selectedCatalogueL2?.name}}</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'ADMIN_CATALOGUE_REQUISITION_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span class="c-pointer enable-breadcrumb" (click)="navigateToCatalogueL1()">Catalogue</span>
    <span class="mx-2">|</span>
    <span>Requisition</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'APPROVED_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Approval</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'ADMIN_PURCHASE_ORDER_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Purchase Order</span>
  </div>
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'ADMIN_INVOICE_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Invoice</span>
  </div>

  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'PURCHASE_REQUEST_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('DASHBOARD_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Requisition</span>
  </div>
<!-- 
  <div class=" p-2 my-2  medium-font" *ngIf="pageName == 'MATERIAL_PAGE'">
    <span class="c-pointer enable-breadcrumb" (click)="navigateToPage('MATERIAL_PAGE')">Home</span>
    <span class="mx-2">|</span>
    <span>Materials</span>
  </div> -->
  <!-- <div class="breadcrum-divider my-1" [ngClass]="pageName == 'ONBOARDING_PAGE' || pageName == 'MATERIAL_PAGE'? 'd-none':''"></div> -->
</div>