<div class="mt-10  bg-blue-50  h-screen">
  <div *ngIf="!isSuperAdmin">
    <div class="flex w-4/6 mx-auto items-center justify-center">
      <ol class="flex text-gray-900 items-center relative w-full">
        <div class="absolute top-1/2 left-1/2 w-full h-0.5  bg-gray-300 -translate-x-1/2"></div>

        <!-- Basic Details -->
        <li class="relative flex-1 flex flex-col items-center text-center">
          <div>
            <span class="items-center step" *ngIf="currentStageIndex == 1 && activeStage != 'BASIC_DETAILS'">1</span>
            <span class="flex items-center complete" *ngIf="currentStageIndex > 1 && activeStage != 'BASIC_DETAILS'">
              <i-lucide color="#fff" [size]="16" name="check" class="my-icon"></i-lucide>
            </span>
            <span class="items-center active" *ngIf="activeStage === 'BASIC_DETAILS'"></span>
          </div>
          <p class="text-xs font-bold text-gray-500 mt-1">Basic Detail</p>
          <div class="absolute top-1/2 w-full left-1/2 h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>

        <!-- DSC Details -->
        <li class="relative flex-1 flex flex-col items-center text-center" *ngIf="!isSuperAdmin">
          <div>
            <span class=" items-center step flex" *ngIf="currentStageIndex == 2 && activeStage != 'DSC_DETAILS'">2</span>
            <span class=" items-center complete" *ngIf="currentStageIndex > 2 && activeStage != 'DSC_DETAILS'">
              <i-lucide color="#fff" [size]="16" name="check" class="my-icon"></i-lucide>
            </span>
            <span class=" items-center active" *ngIf="activeStage === 'DSC_DETAILS'">
            </span>
          </div>
          <p class="text-xs font-bold text-gray-500 mt-1">DSC</p>
          <div class="absolute top-1/2 w-full left-0 h-0.5 bg-gray-300 -translate-x-1/2"></div>
        </li>

      </ol>
    </div>
  </div>

  <div *ngIf="activeStage == 'BASIC_DETAILS'">
    <app-basic-details (onNext)="onNextPage()"></app-basic-details>
  </div>

  <div *ngIf="activeStage == 'DSC_DETAILS'">
    <app-dsc-page (onNext)="onNextPage()" (onPrevious)="onPreviousPage()"></app-dsc-page>
  </div>
</div>