import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { UserUiDto } from '../../models/user/UserUiDto';
import { firstValueFrom } from 'rxjs';
import { selectUserUiDto } from '../../state-management/session.features';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stepper-basic-and-dsc',
  templateUrl: './stepper-basic-and-dsc.component.html',
  styleUrls: ['./stepper-basic-and-dsc.component.sass']
})
export class StepperBasicAndDscComponent implements OnInit, AfterViewInit, OnDestroy {
  userUiDto?: UserUiDto;

  isSuperAdmin: boolean = false;
  currentStageIndex: number = 1;
  activeStage: 'BASIC_DETAILS' | 'DSC_DETAILS' = 'BASIC_DETAILS';

  constructor(
    private store: Store,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadUserUiDto();
  }

  ngAfterViewInit(): void {

  }

  async loadUserUiDto() {
    this.userUiDto = await firstValueFrom(this.store.pipe(select(selectUserUiDto)));
    this.isSuperAdmin = ApplicationUtils.isSuperAdmin(this.userUiDto!);

    this.checkCurrentStage();

    let dscCertificateDtos = this.userUiDto?.dscCertificateDtos || [];

    if (this.userUiDto!.firstName && this.userUiDto!.lastName && this.userUiDto!.mobileWrapper && dscCertificateDtos.length == 0) {
      this.onNextPage();
    }
  }

  checkCurrentStage() {
    if (!this.userUiDto!.firstName || !this.userUiDto!.lastName || !this.userUiDto!.mobileWrapper) {
      this.currentStageIndex = 1;
    } else {
      let dscCertificateDtos = this.userUiDto?.dscCertificateDtos || [];
      this.currentStageIndex = dscCertificateDtos.length > 0 ? 3 : 2;
    }
  }

  onNextPage() {
    if (this.activeStage == 'BASIC_DETAILS') {
      if (this.isSuperAdmin) {
        this.router.navigate(['SuperAdmin/Dashboard'], { skipLocationChange: true });
      } else {
        this.activeStage = 'DSC_DETAILS';
      }
    } else if (this.activeStage == 'DSC_DETAILS') {
      this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true });
    }

    this.checkCurrentStage()
  }

  onPreviousPage() {
    if (this.activeStage == 'DSC_DETAILS') {
      this.activeStage = 'BASIC_DETAILS';
    }
  }

  ngOnDestroy(): void {
  }
}
