<div class="my-5 ">
  <div class="flex w-4/6 mx-auto px-8 pb-4">
    <div class="w-full bg-white pt-6 pb-8 px-8 rounded-lg">
      <div class="flex items-center mb-6">
        <div class="">
          <span class="h-10 w-10  items-center justify-between bg-blue-100 rounded-lg inline-flex p-2">
            <i-lucide name="shield-check" class="my-icon" color="#374151"></i-lucide>
          </span>
        </div>
        <div class="inline-block ms-2">
          <div class="font-semibold text-gray-800 text-lg">
            Add Digital Signature Certificate (DSC)
          </div>
          <div class="text-gray-500 text-xs text-medium font-medium">
            Add your digital signature for secure processing and compliance.
          </div>
        </div>
      </div>

      <div class="bg-orange-50 w-full rounded-lg p-6">
        <div class="flex flex-row gap-6">
          <div>
            <img src="assets/images/dsc.svg" class="dsc-image-large">
          </div>
          <div class="flex flex-col gap-2">
            <div class="text-sm text-black font-medium">
              You have not registered with Digital signature certificate(DSC) yet. Please click on the the like below to
              add DSC
            </div>
            <div class="flex items-center">
              <p-button styleClass="px-3 py-1.5" (onClick)="addDscEvent()" [loading]="isAddDscLoading"
                class="text-center inline-flex items-center text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm  me-3">
                <i-lucide name="plus" color="#fff" [size]="18" class="my-icon inline-flex "></i-lucide> {{dscCertificateDtos.length > 0 ? 'Add Another DSC' : 'Add DSC' }}
              </p-button>
              <!-- <p-button styleClass="px-3 py-1.5"
                class="text-center inline-flex items-center text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm">
                <i-lucide name="plus" color="#fff" [size]="18" class="my-icon inline-flex"></i-lucide> Add Another DSC
              </p-button> -->
            </div>
            <div class="mt-3">
              <span class="bg-blue-50 text-blue-500 px-2 py-0.5 rounded-2xl  me-1">
                Note:
              </span>
              <span class="text-xs"> Please make sure your e-Token card has been attached to USB port </span>
            </div>
          </div>
          <div class="basis-4/12">

          </div>
        </div>
      </div>

      <div class="mt-3 border border-gray-300  rounded-md">
        <p-table  [scrollable]="true" scrollHeight="200px" [virtualScroll]="true" [virtualScrollItemSize]="46">
          <ng-template pTemplate="header">
            <tr class="ng-prime-table">
              <th pSortableColumn="code" class="border-e w-12"></th>
              <th pSortableColumn="code" class="border-e">
                DSC certificate name
                <p-sortIcon field="code" class="float-end border-e"></p-sortIcon>
              </th>
              <th pSortableColumn="price" class="border-e">Issued by</th>

              <th pSortableColumn="quantity" class="border-e">
                Expiry date
                <p-sortIcon field="quantity" class="float-end border-e"></p-sortIcon>
              </th>

              <th pSortableColumn="price">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-documentArchive let-i="rowIndex">
            <tr>
              <td></td>
              <td class="">{{i+1}}</td>
              <td class=""> not binded </td>

              <td class="">
                not binded

              </td>
              <td class="">
                13/11/24
              </td>
              <td>


                <span>
                  <i-lucide name="trash-2" color="rgb(239 68 68)" class="my-icon"></i-lucide>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>


      </div>



    </div>

  </div>
  <div class="flex w-4/6 mx-auto  px-8">
    <div class="flex flex-row justify-between   w-full">
      <div class=" text-start flex ">
        <p-button styleClass="px-3 py-1.5" (onClick)="onClickBack()"
          class="px-3 py-1.5 flex items-center" class="text-center items-center bg-white text-blue-700 border border-blue-700 hover:border-blue-600 focus:ring-4 focus:ring-blue-600 font-medium rounded text-sm  inline-flex">
          <i-lucide [size]="16" name="move-left" class="my-icon flex-inline me-1"></i-lucide>
          Back</p-button>
      </div>
      <div class=" text-end flex">
        <p-button styleClass="px-3 py-1.5" [loading]="isDeleteDscLoading" (onClick)="deleteDscTransactionEvent()"
          class="text-center items-center bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm inline-flex">
          Finish <i-lucide [size]="16" name="move-right" class="my-icon inline-flex ms-1"></i-lucide></p-button>
      </div>

    </div>
  </div>
</div>