export const environment = {
	production: false,
	baseUrl: 'http://localhost:8081',
	firebaseConfig: {
		apiKey: "AIzaSyCJgkplKLcjza-RpNaOUfp2ZanMnW6AR-0",
		authDomain: "pkc-advisory-dev.firebaseapp.com",
		projectId: "pkc-advisory-dev",
		storageBucket: "pkc-advisory-dev.appspot.com",
		messagingSenderId: "168398225716",
		appId: "1:168398225716:web:923c56ed4a3f2e0da76561",
		measurementId: "G-DRCGXS5MNB"
	}
};