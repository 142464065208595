<div class="my-5 h-screen">
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="saveUserProfile()">
    <div class="flex w-4/6 mx-auto">
      <div class="w-full bg-white pt-6 pb-8 px-8 rounded-lg">
        <div class="flex items-center mb-6">
          <div class="">
            <span class="h-10 w-10  items-center justify-between bg-blue-100 rounded-lg inline-flex p-2">
              <i-lucide name="building-2" color="#334155" class="my-icon"></i-lucide>
            </span>
          </div>
          <div class="inline-block ms-2">
            <div class="font-semibold text-gray-800 text-lg">
              Basic details
            </div>
            <div class="text-gray-500 text-xs text-medium font-medium">
              Add your official information.
            </div>
          </div>
        </div>

        <div class="flex mt-1 gap-6">
          <div class="md:basis-1/2  mb-4">
            <div class="">
              <label for="firstName" class="text-gray-700  text-sm font-medium leading-4 text-start">First Name</label>
              <input type="text"
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-600 text-sm"
                id="firstName" formControlName="firstName" />
              <div *ngIf="fc['firstName'].invalid && (fc['firstName'].dirty || fc['firstName'].touched)">
                <p class="text-red-500 flex" *ngIf="fc['firstName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex justify-center items-center me-1"></i-lucide>First
                  Name Required
                </p>
                <p class="text-red-500 flex" *ngIf="fc['firstName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex justify-center items-center me-1"></i-lucide>Maximum length 100
                </p>
              </div>
            </div>
          </div>
          <div class="md:basis-1/2 mb-4">
            <div class="">
              <label for="lastName" class="text-gray-700  text-sm font-medium leading-4 text-start">Last Name</label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-600 text-sm"
                id="lastName" formControlName="lastName" />
              <div *ngIf="fc['lastName'].invalid && (fc['lastName'].dirty || fc['lastName'].touched)">
                <p class="text-red-500 flex" *ngIf="fc['lastName'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex justify-center items-center me-1"></i-lucide>Last
                  Name Required
                </p>
                <p class="text-red-500 flex" *ngIf="fc['lastName'].errors?.['maxlength']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon inline-flex justify-center items-center me-1"></i-lucide>Maximum length 100
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex mt-1 gap-6">
          <div class="md:basis-1/2 mb-4">
            <label for="orgDesignation" class="text-gray-700  text-sm font-medium leading-4 text-start">
              Designation
            </label>
            <select name="orgDesignation" formControlName="orgDesignation" id="orgDesignation"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-400 h-8 px-2 py-1.5 cursor-pointer text-gray-800 text-sm form-select">
              <option value="" disabled>Select Designation (Optional)</option>
              <option value="managing_director">Managing Director</option>
              <option value="director">Director</option>
              <option value="procurement_head">Procurement Head</option>
            </select>
            <div *ngIf="fc['orgDesignation'].invalid && (fc['orgDesignation'].dirty || fc['orgDesignation'].touched)">
              <p  class="text-red-500 flex " *ngIf="fc['orgDesignation'].errors?.['required']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                  class="my-icon justify-center items-center inline-flex me-1"></i-lucide>Select Designation
              </p>
             
            </div>

          </div>
          <div class="md:basis-1/2 mb-4">
            <div class="text-start">
              <label class="text-gray-700  text-sm font-medium leading-4" for="mobileNo">Phone Number</label>
              <div class="flex w-full mb-3">
                <div class="input-group-append relative">
                  <span class="border border-solid border-gray-300  border-r-0 rounded-l-lg h-8 flex scroll-px-32 ">
                    <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                      [countryCode$]="countryCodeForMobileNo$"></app-country-code-select>
                  </span>
                </div>

                <input type="text"
                  class="border border-solid border-gray-300  border-l-0 rounded-r-lg h-8 flex  px-3  w-full "
                  id="mobileNo" placeholder="Phone Number" formControlName="mobileNo">
              </div>
              <div *ngIf="fc['mobileNo'].invalid && (fc['mobileNo'].dirty || fc['mobileNo'].touched)">
                <p class="text-red-500 flex" *ngIf="fc['mobileNo'].errors?.['required']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon  justify-center items-center inline-flex me-1"></i-lucide>Phone Number
                </p>
                <p class="text-red-500 flex" *ngIf="fc['mobileNo'].errors?.['pattern']">
                  <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                    class="my-icon justify-center items-center inline-flex  me-1"></i-lucide>Invalid
                </p>
              </div>

            </div>
          </div>
        </div>
        <div class="flex mt-1 gap-6">
        </div>

      </div>
    </div>
    <div class="flex w-4/6 mx-auto">
      <div class=" mt-5 flex flex-row justify-end w-full">
        <div class="  text-end flex">
          <p-button styleClass="px-3 py-1.5" [loading]="isLoading" (onClick)="saveUserProfile()" *ngIf="!isSuperAdmin"
            class="text-center items-center  bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm flex-inline ">
            Next: DSC
            <i-lucide [size]="16" name="move-right" class="my-icon inline-flex ms-1"></i-lucide>
          </p-button>

          <p-button styleClass="px-3 py-1.5" [loading]="isLoading" (onClick)="saveUserProfile()" *ngIf="isSuperAdmin"
            class="text-center items-center basis-1/2 bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm inline-flex">
            Finish
            <i-lucide [size]="16" name="move-right" class="my-icon inline-flex ms-1"></i-lucide>
          </p-button>
        </div>
      </div>
    </div>
  </form>


</div>