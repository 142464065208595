<div class="bg-blue-50 h-screen ">
  <div class="px-8 py-12 ">
    <div class="flex justify-center items-center mb-6">
      <div class="text-2xl text-gray-800 font-bold w-96 text-center">
        <span>Change your password</span>
      </div>
    </div>
    <div class="flex justify-center items-center mb-6 ">
      <div class=" bg-white rounded-lg  w-[452px] ">
        <div class="p-8">
        <div class=" text-gray-700 text-center text-base font-medium mb-4">
          Your new password must be different to previous passwords
        </div>

        <div class=" text-start mb-6">
          <label class="text-gray-700  text-sm font-medium leading-4 text-start" for="userOtp">
            Create password</label>
          <div class="flex w-full   self-stretch rounded-md border border-solid border-gray-300">
            <input #password [type]="isPasswordVisible ? 'text' : 'password'" [formControl]="ctrlPassword"
              class="bg-white w-full rounded-md flex-col self-stretch  h-8 px-2 py-0 text-gray-600 text-sm"
              id="password" placeholder="Password" (keyup)="onChange()"
              [ngClass]="{'border-danger': ctrlPassword.invalid && (ctrlPassword.dirty || ctrlPassword.touched)}">
            <div class="input-group-append">
              <span class=" h-8 flex pt-2 px-3" id="basic-addon2" (click)="togglePasswordVisibility()"
                [ngClass]="{'border-danger': ctrlPassword.invalid && (ctrlPassword.dirty || ctrlPassword.touched)}">
                <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isPasswordVisible"></i-lucide>
                <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isPasswordVisible"></i-lucide>
              </span>
            </div>
          </div>

          <div class="mb-3 text-start" *ngIf="ctrlPassword.invalid && (ctrlPassword.dirty || ctrlPassword.touched)">
            <p class="text-red-500 flex" *ngIf="ctrlPassword.errors?.['required']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                class="my-icon inline-flex items-center justify-center me-1"></i-lucide>
              Password is Required
            </p>
            <p class="text-red-500 flex" *ngIf="ctrlPassword.errors?.['pattern'] || ctrlPassword.errors?.['minlength']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                class="my-icon inline-flex items-center justify-center  me-1"></i-lucide>
              Password must be more than 12 characters, containing at least one capital letter, one lowercase
              letter,
              one number and one special character.
            </p>
            <p class="text-red-500 flex" *ngIf="ctrlPassword.errors?.['maxlength']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                class="my-icon  items-center justify-center inline-flex me-1"></i-lucide>
              Maximum Length Exceed
            </p>
          </div>
        </div>


        <div class=" text-start mb-6">
          <label class="text-gray-700  text-sm font-medium leading-4 text-start" for="userOtp">
            Confirm password</label>
          <div class="flex w-full    self-stretch rounded-md border border-solid border-gray-300">
            <input #password [type]="isRepeatPasswordVisible ? 'text' : 'password'" [formControl]="ctrlConfirmPassword"
              class="bg-white w-full rounded-md flex-col self-stretch  h-8 px-2 py-0 text-gray-600 text-sm"
              id="password" placeholder="Password" (keyup)="onChange()"
              [ngClass]="{'border-danger': ctrlConfirmPassword.invalid && (ctrlConfirmPassword.dirty || ctrlConfirmPassword.touched)}">
            <div class="input-group-append">
              <span class=" h-8 flex pt-2 px-3" id="basic-addon2" (click)="toggleRepeatPasswordVisibility()"
                [ngClass]="{'border-danger': ctrlConfirmPassword.invalid && (ctrlConfirmPassword.dirty || ctrlConfirmPassword.touched)}">
                <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isRepeatPasswordVisible"></i-lucide>
                <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isRepeatPasswordVisible"></i-lucide>
              </span>
            </div>
          </div>

          <div class="mb-3 text-start" *ngIf="ctrlConfirmPassword.dirty || ctrlConfirmPassword.touched">
            <p class="text-red-500 flex" *ngIf="ctrlConfirmPassword.invalid && ctrlConfirmPassword.errors?.['required']">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon items-center justify-center inline-flex me-1"></i-lucide>
              Confirm Password is Required
            </p>
            <p class="text-red-500 flex" *ngIf="!ctrlConfirmPassword.invalid && !(_isPasswordMatch$ | async)">
              <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)" class="my-icon  items-center justify-center inline-flex me-1"></i-lucide>
              Password Not Match
            </p>
          </div>
        </div>

        <div class="grid text-end mb-8 ">
          <p-button label="Change Password" [loading]="isLoading"
            class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-3 py-1.5 "
            (click)="changeUserPassword()"></p-button>
        </div>
        <div class=" text-start">
          <div class="text-xs text-gray-700 font-semibold mb-2">
            Password policies
          </div>

          <div class="font-normal text-xs text-gray-500 flex items-center">
            <span class="me-1"
              [ngClass]="{'step' : passwordLength == 0, 'danger' : passwordLength < 12 && passwordLength > 0, 'complete' : passwordLength >= 12}">
              <i-lucide color="#fff" [size]="16" name="check" class="my-icon" *ngIf="passwordLength >= 12"> </i-lucide>
              <i-lucide color="#fff" [size]="16" name="x" class="my-icon" *ngIf="passwordLength < 12 && passwordLength > 0"> </i-lucide>
            </span>
            Password length should be minimum 12 characters.
          </div>
          <div class="font-normal text-xs text-gray-500 flex items-center">
            <span class="me-1"
              [ngClass]="{'step' : passwordLength == 0, 'danger' : passwordLength > 0 && ctrlPassword.hasError('pattern'), 'complete' : passwordLength > 0 && !ctrlPassword.hasError('pattern')}">
              <i-lucide color="#9CA3AF" [size]="16" name="check" class="my-icon" *ngIf="passwordLength > 0 && !ctrlPassword.hasError('pattern')"> </i-lucide>
              <i-lucide color="#9CA3AF" [size]="16" name="x" class="my-icon" *ngIf="passwordLength > 0 && ctrlPassword.hasError('pattern')"> </i-lucide>
            </span>
            At least 1 uppercase, 1 lowercase, 1 digit, and 1 special character.
          </div>
        </div>
      </div>
    
    </div>
  </div>
  <div class=" flex justify-center items-center mb-6 ">
    <div class="text-sm text-gray-800 font-medium underline cursor-pointer" (click)="navigateToLoginPage()">
      <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block" style="color: black;"></i-lucide> Back to
      login
    </div>
  </div>
    <div class="flex justify-center items-center mt-12">
      <div class="w-96 text-center text-xs text-gray-700 font-medium">
        Copyright 2024 Procurext - All rights reserved.
         <span class="underline" (click)="openTermsDialog()">Terms of Service</span> and <span class="underline"
          (click)="openPrivacyDialog()">
          Privacy Policy
        </span>
      </div>
    </div>
  </div>

</div>


<p-dialog [style]="{width: '50vw'}" [(visible)]="isShowPrivacyDialog" [modal]="true" [draggable]="false" header="PRIVACY POLICY">
  <div class="scroll-container">
    <div #privacyHeader></div>
    <div class="m-3" style="white-space: normal;">
      <p [innerHTML]="privacyAndTermsOfUseDto?.privacyPolicy ?? '' | safe: 'html'"></p>
    </div>
  </div>
  <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(privacyHeader)">
    <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
  </button>
</p-dialog>

<p-dialog [style]="{width: '50vw'}" [(visible)]="isShowTermsDialog" [modal]="true" [draggable]="false" header="TERMS OF USE">
  <div class="scroll-container">
    <div #termsHeader></div>
    <div class="m-3" style="white-space: normal;">
      <p [innerHTML]="privacyAndTermsOfUseDto?.termsOfUse ?? '' | safe: 'html'"></p>
    </div>
    <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(termsHeader)">
      <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
    </button>
  </div>
</p-dialog>