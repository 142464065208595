import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { SuperAdminDashboardService } from '../../services/super-admin-dashboard.service';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { EmailTemplateService } from '../../services/email-template.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { FileSaveResponseDto } from '../../models/FileSaveResponseDto';
import { FileInfoDto } from '../../models/FileInfoDto';
import { FileService } from 'src/app/shared/services/file.service';
import { SafeUrl } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Pattern } from '../../util/Patterns';
import { UserInviteDto } from '../../models/UserInviteDto';
import { name } from '../../state-management/session.features';

@Component({
  selector: 'app-new-organization-drawer',
  templateUrl: './new-organization-drawer.component.html',
  styleUrls: ['./new-organization-drawer.component.sass']
})
export class NewOrganizationDrawerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() closeCreateOrganizationDrawer: EventEmitter<boolean> = new EventEmitter<boolean>(true);
  @Input() organizationUiDto$ = new Observable<OrganizationWrapperUiDto | undefined>();
  @Input() organization$ = new Observable<OrganizationWrapperUiDto | undefined>();

  formGroup: FormGroup;

  isEdit: boolean = false;
  isLoading: boolean = false;
  imageTypes = ['image/png', 'image/jpg', 'image/jpeg'];

  imageTypeBanner?: string;
  imageTypeLogo?: string;
  logoFileName: string = '';
  bannerFileName: string = '';
  imageUrlLogo?: string | ArrayBuffer | SafeUrl | null;
  imageUrlBanner?: string | ArrayBuffer | SafeUrl | null;

  currentFileLogo?: File;
  currentFileBanner?: File;
  
  remainingCharacters: number = 0;
  organizationUiDto?: OrganizationWrapperUiDto;

  _isSaveButtonEnable$ = new BehaviorSubject<boolean>(true);

  organizationSubscription$?: Subscription;

  constructor(private fb: FormBuilder,
    private superAdminService: SuperAdminDashboardService,
    private emailTemplateService: EmailTemplateService,
    private fileService: FileService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    private superAdminDashboardService: SuperAdminDashboardService,
  ) {
    this.formGroup = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(100)]],
      orgCode: ['', [Validators.required, Validators.maxLength(100)]],
      logo: [null],
      bannerImage: [null],
      adminDomainName: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(Pattern.url2)]],
      bidderDomainName: ['', [Validators.required, Validators.maxLength(100), Validators.pattern(Pattern.url2)]],
    });

  }



  ngOnInit(): void {
    this.organizationSubscription$ = this.organizationUiDto$.subscribe(data => {
      if (data) {
        this.organizationUiDto = data;
        this.isEdit = true;
        this.populateFormGroup();

      } else {
        this.isEdit = false;
        this.organizationUiDto = undefined;
      }
    })

    this.formGroup.controls['name'].valueChanges.subscribe(value => {
      if (value && value != '') {
        this.remainingCharacters = value;
      } else {
        this.remainingCharacters = 0
      }
    });

  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  get fc() { return this.formGroup.controls; }

  chooseFile(event: any, type: string) {
    let currentFile = event.target.files[0];
    if (!this.imageTypes.includes(currentFile!.type)) {
      this.messageService.add({ severity: 'error', summary: 'Failed', detail: "Extension not supported" });
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(currentFile);
    reader.onload = (e) => {
      let size = (currentFile?.size! / 1024) / 1024;

      // Create New Image
      var newImage = new Image();
      newImage.src = e.target!.result as string;

      newImage.onload = (el) => {
        if (type == 'BANNER') {
          this.imageTypeBanner = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        } else {
          this.imageTypeLogo = newImage.width > newImage.height ? 'Rectangle' : 'Portrait';
        }

        if (size > 1) {
          var canvas = document.createElement("canvas");
          canvas.width = newImage.width;
          canvas.height = newImage.height;

          var ctx = canvas.getContext("2d");
          ctx?.drawImage(el.target as CanvasImageSource, 0, 0, canvas.width, canvas.height);
          var srcEncoded;
          if (size >= 5) {
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.1);
          } else {
            // size less then 5 MB
            srcEncoded = ctx?.canvas.toDataURL('image/jpeg', 0.5);
          }

          ApplicationUtils.base64toFile(srcEncoded, currentFile!.name, currentFile!.type)
            .then((file: File) => {
              if (type == 'BANNER') {
                this.currentFileBanner = file;
                this.bannerFileName = file?.name as string;
                this.formGroup.controls['bannerImage'].patchValue(this.currentFileBanner);
              } else {
                this.currentFileLogo = file;
                this.logoFileName = file?.name as string;
                this.formGroup.controls['logo'].patchValue(this.currentFileLogo);
              }
            })
        } else {
          if (type == 'BANNER') {
            this.currentFileBanner = currentFile;
            this.bannerFileName = currentFile?.name as string;
            this.formGroup.controls['bannerImage'].patchValue(this.currentFileBanner);
          } else {
            this.currentFileLogo = currentFile;
            this.logoFileName = currentFile?.name as string;
            this.formGroup.controls['logo'].patchValue(this.currentFileLogo);
          }
        }

        if (type == 'BANNER') {
          this.imageUrlBanner = reader.result?.toString();
        } else {
          this.imageUrlLogo = reader.result?.toString();
        }
      }
    }
  }

  populateFormGroup() {
    if (this.organizationUiDto != null) {
      this.isEdit = true;
      this.formGroup.patchValue(this.organizationUiDto);
      this.formGroup.controls['adminDomainName'].disable();
      this.formGroup.controls['bidderDomainName'].disable();
      this.formGroup.controls['orgCode'].disable();
      if (this.organizationUiDto.bannerImage) {
        this.imageUrlBanner = `/downloadLandingBlob?fileId=${this.organizationUiDto.bannerImage?.fileId}`;
        this.bannerFileName = this.organizationUiDto.bannerImage?.displayName as string;
        this.formGroup.get('bannerImage')?.setValue(this.organizationUiDto.bannerImage);
      }

      if (this.organizationUiDto.logo) {
        this.imageUrlLogo = `/downloadLandingBlob?fileId=${this.organizationUiDto.logo?.fileId}`;
        this.logoFileName = this.organizationUiDto.logo?.displayName as string;
        this.formGroup.get('logo')?.setValue(this.organizationUiDto.logo);
      }


      this.formGroup.updateValueAndValidity();
    }
  }

  mergeOrganizationUiDto() {
    let formValue = this.formGroup.getRawValue();

    let organizationUiDto = new OrganizationWrapperUiDto();
    if (this.organizationUiDto) {
      organizationUiDto = ApplicationUtils.clone(this.organizationUiDto) as OrganizationWrapperUiDto;
    }

    organizationUiDto.name = formValue.name;
    organizationUiDto.orgCode = formValue.orgCode;
    organizationUiDto.adminDomainName = formValue.adminDomainName;
    organizationUiDto.bidderDomainName = formValue.bidderDomainName;
    organizationUiDto.logo = formValue.logo;
    organizationUiDto.bannerImage = formValue.bannerImage;

    return organizationUiDto;
  }

  handlevalidSubmit() {
    console.log(this.organizationUiDto);

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this._isSaveButtonEnable$.next(false);
    if (this.currentFileLogo || this.currentFileBanner) {
      this.uploadFiles();
    } else {
      this.saveOrganization();
    }
  }

  uploadFiles() {
    this.isLoading = true;

    let metaDataList: string[] = [];
    let formData = new FormData();

    if (this.currentFileBanner) {
      let metaData = {
        'mimeType': this.currentFileBanner.type,
        'version': 0,
        'publicApi': true,
        'dataType': this.imageTypeBanner
      };

      metaDataList.push(JSON.stringify(metaData));
      formData.append("files", this.currentFileBanner);
    }

    if (this.currentFileLogo) {
      let metaData = {
        'mimeType': this.currentFileLogo.type,
        'version': 0,
        'publicApi': true,
        'dataType': this.imageTypeLogo
      };

      metaDataList.push(JSON.stringify(metaData));
      formData.append("files", this.currentFileLogo);
    }

    formData.append("metaDataList", JSON.stringify(metaDataList));

    this.fileService.uploadMultipleFile(formData).subscribe(apiResponseDto => {
      if (apiResponseDto) {
        if (apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {

          let fileSaveResponseDtoList = apiResponseDto.data as FileSaveResponseDto[];

          for (let fileSaveResponseDto of fileSaveResponseDtoList) {
            let currentFileInfoDto = new FileInfoDto();
            currentFileInfoDto.fileId = fileSaveResponseDto.fileId;
            currentFileInfoDto.fileName = fileSaveResponseDto.fileName;
            currentFileInfoDto.displayName = fileSaveResponseDto.fileName;
            currentFileInfoDto.dataType = fileSaveResponseDto.dataType;
            currentFileInfoDto.uploadDate = new Date().toDateString();


            if (this.bannerFileName == fileSaveResponseDto.fileName) {
              currentFileInfoDto.fileSize = this.currentFileBanner?.size.toString();
              currentFileInfoDto.fileType = this.currentFileBanner?.type;
              this.formGroup.get('bannerImage')?.setValue(currentFileInfoDto);
            }

            if (this.logoFileName == fileSaveResponseDto.fileName) {
              currentFileInfoDto.fileSize = this.currentFileLogo?.size.toString();
              currentFileInfoDto.fileType = this.currentFileLogo?.type;
              this.formGroup.get('logo')?.setValue(currentFileInfoDto);
            }
          }

          this.formGroup.updateValueAndValidity();
          this.saveOrganization();
        }
      } else {
        this.isLoading = false;
      }
    })
  }

  saveOrganization() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }   

    this._isSaveButtonEnable$.next(false);

    this.isLoading = true;
    this._isSaveButtonEnable$.next(false);

    let organizationUiDto = this.mergeOrganizationUiDto()

    this.superAdminService.saveOrganizationUiDto(organizationUiDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        console.log("apiResponseDto Data", apiResponseDto);
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;

          let organizationUiDto = apiResponseDto.data as OrganizationWrapperUiDto;
          this.superAdminDashboardService.loadSuperAdminDashboardData();

          // if (newOrganizationCreation) {
          //   console.log("Creating new Email Templates for new Organization");
          //   this.emailTemplateService.saveEmailTemplatesToFirestore(organizationUiDto.orgCode!);
          // }

          // Update Organization List
          if (this.isEdit) {
            this.superAdminService.updateOrganizationList(organizationUiDto, this.isEdit)
            this.messageService.add({severity: 'success', summary: 'Updated', detail: "Organisation Updated Successfully" });
            this.closeDrawer();
            this.formGroup.reset();
            this.imageUrlLogo = null;
            this.imageUrlBanner = null;
          } else {
            setTimeout(() => {
              this.messageService.add({severity: 'success', summary: 'Success', detail: "Organisation Created Successfully" });
              this._isSaveButtonEnable$.next(true);
              this.closeDrawer();
              this.formGroup.reset();
              this.imageUrlLogo = null;
              this.imageUrlBanner = null;
            }, 2000)

          }

        } else {
          if (apiResponseDto.code == "AUCTION-ERROR-113") {
            this.messageService.add({ severity: 'error', summary: 'Failed', detail: "Organization already existing with " + this.organizationUiDto?.orgCode });

          } else {
            this.messageService.add({ severity: 'error', summary: 'Failed', detail: apiResponseDto.message as string });
            console.log("come here 336");

          }
          this._isSaveButtonEnable$.next(true);
          this.isLoading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: "Error Saving Organization" as string });
        this.isLoading = false;
        this._isSaveButtonEnable$.next(true);
      }
    })

  }

  onChangeAdminDomainName() {
    var domainName = this.fc['adminDomainName'].value;

    if (domainName && (domainName.includes('localhost'))) {
      this.formGroup.controls['adminDomainName'].setValidators(Validators.required);
      this.formGroup.controls['adminDomainName'].updateValueAndValidity();
    } else {
      this.formGroup.controls['adminDomainName'].setValidators([Validators.required, Validators.pattern(Pattern.url2)]);
      this.formGroup.controls['adminDomainName'].updateValueAndValidity();
    }
  }

  onChangeBidderDomainName() {
    var domainName = this.fc['bidderDomainName'].value;

    if (domainName && (domainName.includes('localhost'))) {
      this.formGroup.controls['bidderDomainName'].setValidators(Validators.required);
      this.formGroup.controls['bidderDomainName'].updateValueAndValidity();
    } else {
      this.formGroup.controls['bidderDomainName'].setValidators([Validators.required, Validators.pattern(Pattern.url2)]);
      this.formGroup.controls['bidderDomainName'].updateValueAndValidity();
    }
  }

  closeDrawer() {
    this.closeCreateOrganizationDrawer.emit(false);
    this.formGroup.reset();
    this.imageUrlLogo = null;
    this.imageUrlBanner = null;
    console.log("this.closeCreateOrganizationDrawer", this.closeCreateOrganizationDrawer);

  }

  ngOnDestroy(): void {

  }
}
