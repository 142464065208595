<div class="text-start ">
  <div class="text-2xl text-gray-800 font-semibold">
    Login
  </div>
  <div class="text-gray-500 text-base font-medium mb-7">
    Get into the application
  </div>
  <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="loginWithPassword()">
    <div class=" text-start">
      <label class="text-gray-700  text-sm font-medium  leading-4">
        Email address
      </label>
      <div class="">
        <input formControlName="emailId"
          class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300 h-8 px-2 py-0 text-gray-600 text-sm"
          [ngClass]="{'is-invalid': fc['emailId'].invalid && (fc['emailId'].dirty || fc['emailId'].touched)}"
          type="email" id="welcomeEmail" placeholder="email@company.com">
      </div>
      <div *ngIf="fc['emailId'].invalid && (fc['emailId'].dirty || fc['emailId'].touched)">
        <p class="text-red-500 flex text-sm " *ngIf="fc['emailId'].errors?.['required']">
          <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
          class="my-icon inline-flex items-center justify-center me-1"></i-lucide>
          Required
        </p>
        <p class="text-red-500 flex text-sm" *ngIf="fc['emailId'].errors?.['pattern']">
          <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex  items-center justify-center mt-1"></i-lucide>
          Invalid email address
        </p>
      </div>
    </div>
    <div class="mb-3 text-start">
      <label for="password" class="text-gray-700  text-sm font-medium leading-4">Password</label>
      <div class="flex w-full  self-stretch rounded-md border border-solid border-gray-300">
        <input #password [type]="isPasswordVisible ? 'text' : 'password'" 
          class="bg-white w-full  h-8 px-2 py-0 text-sm text-gray-600 input-placeholder" id="password" formControlName="password"
          placeholder="Password"  formControlName="password"
          [ngClass]="{'border-danger': fc['password'].invalid && (fc['password'].dirty || fc['password'].touched)}">
        <div class="input-group-append">
          <span class=" h-8 flex pt-2 px-3" id="basic-addon2" (click)="togglePasswordVisibility()"
            [ngClass]="{'border-danger': fc['password'].invalid && (fc['password'].dirty || fc['password'].touched)}">
            <i-lucide name="eye" [size]="16" class="my-icon c-pointer" *ngIf="!isPasswordVisible"></i-lucide>
            <i-lucide name="eye-off" [size]="16" class="my-icon c-pointer" *ngIf="isPasswordVisible"></i-lucide>
          </span>
        </div>
      </div>

      <div class="mb-3 text-start flex items-center" *ngIf="fc['password'].invalid && (fc['password'].dirty || fc['password'].touched)">
        <p class="text-red-500 text-sm flex" *ngIf="fc['password'].errors?.['required']">
          <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex items-center justify-center mt-1"></i-lucide>
          Password is Required
        </p>
        <p class="text-red-500 flex text-sm" *ngIf="fc['password'].errors?.['pattern'] || fc['password'].errors?.['minlength']">
          <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex items-center justify-center mt-1"></i-lucide>
          Password must be more than 12 characters, containing at least one capital letter, one lowercase
          letter,
          one number and one special character.
        </p>
        <p class="text-red-500 text-sm flex items-center" *ngIf="fc['password'].errors?.['maxlength']">
          <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex items-center justify-center mt-1"></i-lucide>
          Maximum Length Exceed
        </p>
      </div>
    </div>
    <div class="text-end mb-8">
      <a class="text-blue-600 text-sm font-medium cursor-pointer" (click)="navigateToForgotPassword()">Forgot
        Password?</a>
    </div>
    <!-- <div class="w-full flex flex-row justify-center">
      <p-button (onClick)="loginWithPassword()" label="Login" styleClass= "w-full py-1.5 justify-center "
        class="text-center  text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm">
      </p-button>
    </div> -->
    <div class="flex flex-row justify-center w-full">
      <p-button styleClass="w-full py-1.5 justify-center "  class="text-center items-center  w-full  bg-blue-700 text-white border-2 border-blue-700 hover:border-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-md text-sm" 
      (onClick)="loginWithPassword()">Login</p-button>
    </div>
 
    <div class="text-center text-sm font-semibold text-gray-700 my-2">
      or
    </div>
    <div class="flex flex-row justify-center w-full">
      <p-button (onClick)="navigateToVerifyOtp(VerifyOtpWorkflowEnum.LOGIN_OTP_WORKFLOW)" styleClass="w-full py-1.5 justify-center " 
        class="text-center items-center  w-full text-blue-700 border-2 border-blue-700 hover:border-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm">
        Login with OTP
      </p-button>
    </div>
    <div>

    </div>
  </form>
</div>

<app-loader #appLoader></app-loader>