<div>
  <ng-container [ngTemplateOutlet]="currentView == 'VERIFY_OTP' ? verifyOtpTemplate
    : generateOtpTemplate">
  </ng-container>
</div>

<ng-template #generateOtpTemplate>
  <div class="bg-blue-50 h-screen">
    <div class="px-8 py-12 ">
      <div class="flex justify-center items-center mb-6">
        <div class="text-2xl text-gray-800 font-bold w-96 text-center">
          <span>Login with OTP</span>
        </div>
      </div>
      <div class="flex justify-center items-center mb-6">
        <div class=" bg-white rounded-lg p-8 w-96">
          <div class=" text-gray-700 text-center text-base font-medium mb-4">
            Enter your registered email address
          </div>
          <div class=" text-start mb-6">
            <label class="text-gray-600  text-sm font-medium leading-4 text-start" for="userOtp">
              Enter email address
            </label>
            <input type="text" id="otp" [formControl]="ctrlEmail" (keyup.enter)="loginWithOtp()"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-600 text-sm"
              [ngClass]="{'is-invalid': ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)}" />

            <div class="text-start" *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
              <p class="text-red-500 flex " *ngIf="ctrlEmail.errors?.['required']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex items-center justify-center me-1"></i-lucide>
                Required
              </p>
              <p class="text-red-500  flex" *ngIf="ctrlEmail.errors?.['pattern']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex items-center justify-center me-1"></i-lucide>
                Invalid email address
              </p>
            </div>
          </div>

          <div class="flex flex-row justify-center w-full mb-8 ">
            <p-button (onClick)="generateOTP()" label="Get OTP" [loading]="isLoading"
            styleClass="w-full py-1.5 justify-center "  class="text-center items-center  w-full  bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm" >
            </p-button>
          </div>
        </div>
      </div>

      <div class=" flex justify-center items-center mb-6 ">
        <div class="text-sm text-gray-800 font-medium underline cursor-pointer" (click)="navigateToLoginPage()">
          <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block" style="color: black;"></i-lucide> Back to
          Login
        </div>
      </div>

      <div class="flex justify-center items-center fixed-footer">
        <div class="w-96 text-center text-xs text-gray-700 font-medium  ">
          Copyright 2024 Procurext - All rights reserved.
           <span class="underline cursor-pointer" (click)="openTermsDialog()">Terms of Service</span> and <span class="underline cursor-pointer" (click)="openPrivacyDialog()">
            Privacy Policy
          </span>
        </div>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #verifyOtpTemplate>
  <div class="bg-blue-50 h-screen">
    <div class="px-8 py-12 ">
      <div class="flex justify-center items-center mb-6">
        <div class="text-2xl text-gray-800 font-bold">
          Check your email for a code
        </div>
      </div>
      <div class="flex justify-center items-center mb-6">
        <div class=" bg-white rounded-lg p-8 w-96 h-96">
          <div class=" text-gray-700 text-center text-base font-medium mb-4">
            A verification code has been sent to your email (<span
              class="text-blue-600 text-base font-bold">{{ctrlEmail.value}}</span>). Check and enter the
            code below
          </div>
          <div class="text-gray-500 text-center text-xs  font-medium mb-8">
            OTP will expire in <span class="text-gray-700 font-bold ">5 minutes</span>, so enter it soon.
          </div>

          <div class=" text-start mb-6">
            <label class="text-gray-600  text-sm font-medium leading-4 text-start" for="userOtp">Enter 6-digit Email
              OTP</label>
            <input type="number" id="otp" [formControl]="ctrlOtp"
              class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-600 text-sm"
              [ngClass]="{'is-invalid': ctrlOtp.invalid && (ctrlOtp.dirty || ctrlOtp.touched)}" />

            <div class="text-start" *ngIf="ctrlOtp.invalid && (ctrlOtp.dirty || ctrlOtp.touched)">
              <p class="e-fielderror e-fielderror-required text-red-500 flex " *ngIf="ctrlOtp.errors?.['required']">
                <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
            class="my-icon inline-flex items-center justify-center me-1"></i-lucide>
                Required
              </p>
            </div>
          </div>

          <!-- <div class="my-3">
            <app-message-toast *ngIf="_showSuccessMsg$ | async" [message]="_successMsg$ | async"></app-message-toast>
            <app-message-toast *ngIf="authService.errorMsg" [isError]="true"
              [message]="authService.errorMsg"></app-message-toast>
          </div> -->

          <div class="flex flex-row justify-center w-full mb-8 ">
            <p-button  (onClick)="onClickVerifyOtp()" [loading]="isLoading"
            styleClass="w-full py-1.5 justify-center "  class="text-center items-center  w-full  bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm" >
            Verify  
          </p-button>
          </div>
          <div class="text-xs font-medium underline cursor-pointer text-center">
            <span *ngIf="countdown > 0">Resend OTP in {{ countdown }}s</span>
            <a class="btn-link" [ngClass]="{'disabled' : disableResendButton}" (click)="onClickResendOtp()" *ngIf="countdown == 0"> Resend OTP</a>
          </div>
        </div>
      </div>
      <div class="flex justify-center items-center mb-2 ">
        <div class="cursor-pointer text-xs text-gray-700 font-medium">
          <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block" style="color: black;"></i-lucide>
          <span class="text-xs font-semibold text-gray-700 underline">Back To The login</span>
        </div>
      </div>
      <div class="flex justify-center items-center fixed-footer">
        <div class="w-96 text-center text-xs text-gray-700 font-medium ">
          By creating an account, you agree to the <span class="underline cursor-pointer" (click)="openTermsDialog()">Terms of Service</span> and <span
            class="underline cursor-pointer" (click)="openPrivacyDialog()">
            Privacy Policy
          </span>
        </div>
      </div>
    </div>

  </div>
</ng-template>


<p-dialog [style]="{width: '50vw'}" [(visible)]="isShowPrivacyDialog" [modal]="true" [draggable]="false" header="PRIVACY POLICY">
  <div class="scroll-container">
    <div #privacyHeader></div>
    <div class="m-3" style="white-space: normal;">
      <p [innerHTML]="privacyAndTermsOfUseDto?.privacyPolicy ?? '' | safe: 'html'"></p>
    </div>
  </div>
  <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(privacyHeader)">
    <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
  </button>
</p-dialog>

<p-dialog [style]="{width: '50vw'}" [(visible)]="isShowTermsDialog" [modal]="true" [draggable]="false" header="TERMS OF USE">
  <div class="scroll-container">
    <div #termsHeader></div>
    <div class="m-3" style="white-space: normal;">
      <p [innerHTML]="privacyAndTermsOfUseDto?.termsOfUse ?? '' | safe: 'html'"></p>
    </div>
    <button class="btn btn-sm btn-outline-primary p-3 floating-button" (click)="moveToTop(termsHeader)">
      <img src="assets/icons/arrowupward.svg" class="svg-icon-class c-pointer">
    </button>
  </div>
</p-dialog>