import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { selectOrganizationUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { ApplicationUtils } from 'src/app/shared/util/ApplicationUtils';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.sass']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  organizationUiDto?: OrganizationWrapperUiDto

  year: number = new Date().getFullYear();

  shareUrl: string = ''
  fbUsername?: string = "100087424403714"
  linkedInUsername?: string = "auctionext"
  whatsAppNumber?: string = "13024153184"
  twitterUsername?: string = "apple"

  isShowPrivacyDialog: boolean = false;
  isShowTermsDialog: boolean = false;

  organizationSubscription$?: Subscription;

  constructor(
    private store: Store
  ) { }

  ngOnInit(): void {
    this.shareUrl = 'https://' + ApplicationUtils.getSubDomainName();
    this.organizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;

        if (this.organizationUiDto) {
          if (this.organizationUiDto.privacyAndTermsOfUseList) {
            this.privacyAndTermsOfUseDto = this.organizationUiDto.privacyAndTermsOfUseList.find(item => item.footerPolicy);
          }
        }
      }
    })
  }

  ngAfterViewInit(): void {
  }

  openTermsDialog() {
    this.isShowTermsDialog = true;
  }

  openPrivacyDialog() {
    this.isShowPrivacyDialog = true;
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe()
    }
  }
}
