<div class="flex relative ">
  <div class="w-8 absolute left-0 top-0 bottom-0 pl-2  h-screen bg-gray-900">
    <div class=" mt-2" (click)="closeDrawer()">
      <i-lucide name="x" color="#fff" [size]="20" class="my-icon cursor-pointer"></i-lucide>
    </div>
    <div class="tr-vertical-text mt-6">
      Close
    </div>
  </div>
  <div class="ms-8 w-full">
    <div class="flex flex-row gap-4 items-center justify-between h-12 px-6 py-4 border-b border-gray-300">
      <div class="inline-flex text-gray-700 font-semibold text-xl leading-4">
        Add Organization
      </div>
    </div>
    <form class="needs-validation w-full" [formGroup]="formGroup" (keyup.enter)="handlevalidSubmit()">

      <div class=" flex px-6 py-6 items-start gap-1 tr-overflow-y-drawer">

        <div class="w-full">
          <div class="grid grid-cols-12 mb-3">
            <div class="col-span-6">
              <label for="title" class="text-gray-600 text-sm font-semibold leading-4">
                <div>Organization name</div>
              </label>
              <div class="flex flex-col">
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm mt-px "
                  type="text" formControlName="name" id="name">
                <div class="flex justify-between flex-row ">
                  <div>
                    <div *ngIf="fc['name'].invalid && (fc['name'].dirty || fc['name'].touched)"
                    class="text-red-500 text-sm flex items-center">
                    <div *ngIf="fc['name'].errors?.['required']">
                      <!-- <span class="mr-1 mt-1">
                        <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                          class="my-icon inline-flex items-center justify-center me-1 "></i-lucide>
                      </span> -->
                      <p class="text-red-500 flex ">
                        <i-lucide name="triangle-alert" [size]="16" color="rgb(239 68 68)"
                          class="my-icon inline-flex items-center justify-center me-1"></i-lucide>
               
            
                      Organization name is required.
                    </p>
                    </div>
                     

                    <div *ngIf="fc['name'].errors?.['maxlength']">
                      <span class="mr-1 mt-1">
                        <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                          class="my-icon inline-flex "></i-lucide>
                      </span>
                      Max length is 100 characters.
                    </div>
                  </div>
                  </div>
                  <div class="text-end text-gray-500 text-xs font-normal flex items-center">
                    {{ remainingCharacters }}/100
                  </div>
                </div>
              </div>
            </div>
            </div>

            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-6">
                <label for="orgCode" class="text-gray-600 text-sm font-semibold leading-4">
                  <div>Organization code </div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm mt-px "
                  type="text" formControlName="orgCode" id="orgCode">
                <div *ngIf="fc['orgCode'].invalid && (fc['orgCode'].dirty || fc['orgCode'].touched)"
                  class="text-red-500 text-sm flex items-center">
                  <div *ngIf="fc['orgCode'].errors?.['required']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>Organization code is required.
                  </div>
                  <div *ngIf="fc['orgCode'].errors?.['maxlength']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>
                    Max length is 100 characters.
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-6">
                <label for="logo">
                  <div class="text-gray-600 text-sm font-semibold leading-4">Upload Logo</div>
                  <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                    Purpose of the requirement
                  </div>
                  <div class="grid grid-cols-12 mb-3 gap-3">
                    <div class="border p-2 rounded-md col-span-6">
                      <div
                        class="w-full h-full flex items-center justify-center py-4 px-3 rounded-md bg-gray-100 cursor-pointer">
                        <div class="flex flex-col items-center">
                          <div>
                            <img src="assets/icons/sidebar-document.svg" class="svg-icon-large c-pointer mb-2">
                          </div>
                          <div class="text-center">
                            <span class="text-link-md">Upload Image</span>
                          </div>
                        </div>
                        <lucide-icon name="upload"></lucide-icon>
                        <input id="logo" type="file" (change)="chooseFile($event, 'LOGO')" accept="image/*"
                          class="hidden" [value]="logoFileName" />
                      </div>
                    </div>

                    <div class="border p-2 rounded-md col-span-6">
                      <div
                        class="w-full h-full flex items-center rounded-md justify-center py-4 px-3 bg-gray-100 cursor-pointer">
                        <div class="flex flex-col items-center">
                          <div>
                            <img src="assets/icons/sidebar-document.svg" class="svg-icon-large c-pointer mb-2"
                              alt="Uploaded Image" *ngIf="!imageUrlLogo">
                            <img [src]="imageUrlLogo" class="svg-icon-large c-pointer mb-2" alt="Uploaded Image"
                              *ngIf="imageUrlLogo">
                          </div>
                          <div class="text-center">
                            <span class="text-link-md">Preview</span>
                          </div>
                        </div>

                        <!-- <input id="file-upload" type="file" onclick="this.value=null;" class="hidden" /> -->
                      </div>

                    </div>
                  </div>
                </label>
                <div class="text-end text-gray-500 text-xs font-normal">
                  [Optional]
                </div>
              </div>
            </div>

            <div class="grid grid-cols-12 mb-3">
              <div class="col-span-6">
                <label for="loginImage">
                  <div class="text-gray-600 text-sm font-semibold leading-4">Upload Login Image </div>
                  <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                    Purpose of the requirement
                  </div>

                  <div class="grid grid-cols-12 mb-3 gap-3">
                    <div class="border p-2 rounded-md col-span-6">
                      <div
                        class="w-full h-full flex items-center rounded-md justify-center py-4 px-3 bg-gray-100 cursor-pointer">
                        <div class="flex flex-col items-center">
                          <!-- <div>
                          <img src="assets/images/document.svg" class="svg-icon-large c-pointer mb-2">
                        </div> -->
                          <div class="text-center">
                            <span class="text-link-md">Upload Image</span>
                          </div>
                        </div>
                        <lucide-icon name="upload"></lucide-icon>
                        <input id="loginImage" type="file" (change)="chooseFile($event, 'BANNER')" accept="image/*"
                          class="hidden" />
                      </div>

                    </div>
                    <div class="border p-2 rounded-md col-span-6">
                      <div
                        class="w-full h-full flex items-center rounded-md justify-center py-4 px-3 bg-gray-100 cursor-pointer">
                        <div class="flex flex-col items-center">
                          <div>
                            <img src="assets/icons/sidebar-document.svg" class="svg-icon-large c-pointer mb-2"
                              alt="Uploaded Image" *ngIf="!imageUrlBanner">
                            <img [src]="imageUrlBanner" class="svg-icon-large c-pointer mb-2" alt="Uploaded Image"
                              *ngIf="imageUrlBanner">
                          </div>
                          <div class="text-center">
                            <span class="text-link-md">Preview</span>
                          </div>
                        </div>

                        <!-- <input id="file-upload" type="file" onclick="this.value=null;" class="hidden" /> -->
                      </div>

                    </div>
                  </div>
                </label>
                <div class="text-end text-gray-500 text-xs font-normal">
                  [Optional]
                </div>
              </div>
            </div>
            <div class="text-xl text-black font-semibold mb-3">
              Domain/URL
            </div>
            <div class="grid grid-cols-12 mb-3 gap-3">
              <div class="col-span-3">
                <label for="adminurl">
                  <div class="text-gray-600 text-sm font-semibold leading-4">Admin URL </div>
                  <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                    Purpose of the requirement
                  </div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm mt-px "
                  type="text" formControlName="adminDomainName" id="adminDomainName"
                  (change)="onChangeAdminDomainName()">
                <div
                  *ngIf="fc['adminDomainName'].invalid && (fc['adminDomainName'].dirty || fc['adminDomainName'].touched)"
                  class="text-red-500 text-sm flex items-center">
                  <div *ngIf="fc['adminDomainName'].errors?.['required']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>Admin URL is required.
                  </div>

                  <div *ngIf="fc['adminDomainName'].errors?.['maxlength']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>
                    Admin URL Max length is 100 characters.
                  </div>

                  <div *ngIf="fc['adminDomainName'].errors?.['pattern']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>
                    Invalid admin domain name
                  </div>

                </div>
              </div>
              <div class="col-span-3">
                <label for="bidderDomainName">
                  <div class="text-gray-600 text-sm font-semibold leading-4">Bidder URL </div>
                  <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                    Purpose of the requirement
                  </div>
                </label>
                <input
                  class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-700 text-sm mt-px "
                  type="text" formControlName="bidderDomainName" id="bidderDomainName"
                  (change)="onChangeBidderDomainName()">
                <div
                  *ngIf="fc['bidderDomainName'].invalid && (fc['bidderDomainName'].dirty || fc['bidderDomainName'].touched)"
                  class="text-red-500 text-sm flex items-center">
                  <div *ngIf="fc['bidderDomainName'].errors?.['required']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>
                    Bidder URL is required.
                  </div>
                  <div *ngIf="fc['bidderDomainName'].errors?.['maxlength']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>
                    Max length is 100 characters.
                  </div>
                  <div *ngIf="fc['bidderDomainName'].errors?.['pattern']">
                    <span class="mr-1 mt-1">
                      <i-lucide name="triangle-alert" [size]="18" color="rgb(239 68 68)"
                        class="my-icon inline-flex "></i-lucide>
                    </span>
                    Invalid bidder domain name
                  </div>
                </div>
              </div>
            </div>

            <!-- As per discussion no need this  -->

            <!-- <div class="text-xl text-black font-semibold mb-3">
            Assign IT admin
          </div> 
          <div class="grid grid-cols-12 mb-3">
            <div class="col-span-6">
              <label for="itAdminEmails" class="text-gray-700 text-sm font-semibold leading-4">
                <div class="text-gray-700 text-sm font-semibold leading-4">Email</div>
                <div class="text-gray-500 text-xs font-normal leading-4 mb-1">
                  You can assign multiple IT admins separated by comma(,)
                </div>
              </label>
              <input
                class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-500 h-8 px-2 py-0 text-gray-600 text-sm mt-px "
                type="text" formControlName="itAdminEmails" id="itAdminEmails" >
               
             </div> 
             <div *ngIf="itAdminEmails?.invalid && (itAdminEmails?.touched || itAdminEmails?.dirty)" class="text-red-500 text-sm flex items-center">
              <div *ngIf="itAdminEmails?.errors?.['required']">At least one email is required.</div>
              <div *ngIf="itAdminEmails?.errors?.['email']">Must be a valid email.</div>
            </div> 
          </div> -->
          </div>
        </div>

        <div class="footer">
          <div class="col-12 text-end my-3 mx-6 ">
            <p-button (onClick)="closeDrawer()" styleClass="px-3 py-1.5" [disabled]="isLoading"
              class="text-center   text-blue-600 border-2 border-blue-600 hover:border-blue-600 hover:bg-blue-300 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm  inline-flex me-3">Cancel</p-button>
            <p-button styleClass="px-3 py-1.5" [loading]="isLoading"
              class="text-center items-center bg-blue-600 text-white border-2 border-blue-600 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm inline-flex"
              (onClick)="handlevalidSubmit()">Save</p-button>
          </div>
        </div>

    </form>
  </div>
</div>