import { DscCallFromEnum } from "../enums/DscCallFromEnum";
import { DscNotificationEnum } from "../enums/DscNotificationEnum";

export class DscTransactionDto {
  clientIp?: string;
  userId?: string;
  orgCode?: string;
  firstName?: string;
  lastName?: string;
  emailId?: string;
  workflowName?: string;
  action?: DscNotificationEnum;
  callFrom?: DscCallFromEnum;

  connectionStatus?: string;
}