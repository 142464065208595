import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { RoutingService } from '../../services/routing.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Pattern } from '../../util/Patterns';
import { PasswordStrengthValidator } from '../../validators/password-strength.validators';
import { BehaviorSubject } from 'rxjs';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { LoaderComponent } from '../loader/loader.component';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { VerifyOtpWorkflowEnum } from '../../enums/VerifyOtpWorkflowEnum';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.sass']
})
export class LoginPageComponent implements OnInit, AfterViewInit, OnDestroy {
  formGroup: FormGroup;

  isPasswordVisible: boolean = false;
  VerifyOtpWorkflowEnum: typeof VerifyOtpWorkflowEnum = VerifyOtpWorkflowEnum;

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  constructor(
    private _router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    public authService: AuthenticationService,
  ) {
    this.formGroup = this.fb.group({
      emailId: ['', [Validators.required, Validators.pattern(Pattern.email)]],
      password: ['', [Validators.required, Validators.minLength(9), PasswordStrengthValidator]]
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  get fc() { return this.formGroup.controls; }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  loginWithPassword() {
    let formValue = this.formGroup.value;

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched()
      return;
    }

    this.appLoader?.openLoaderIcon(ApplicationConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = formValue.emailId;
    let password = formValue.password?.trim();
    this.authService.doAuthentication(emailId!, password!, this.appLoader!);
  }

  navigateToVerifyOtp(otpWorkflow: VerifyOtpWorkflowEnum) {
    this.userService.setOtpWorkflow(otpWorkflow);
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/generateVerifyOtp"], { skipLocationChange: true }));
  }

  navigateToForgotPassword() {
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/resetPassword"], { skipLocationChange: true }));
  }

  ngOnDestroy(): void {

  }

}
