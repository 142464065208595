import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DashboardRedirectionService } from '../../services/dashboard-redirection.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { AuctionEntityDto } from '../../models/user/AuctionEntityDto';
import { AdminSourcingEventsDataHolderService } from '../../services/AdminSourcingEventsDataHolder.service ';
import { RfxUiDto } from '../../models/rfx/RfxUiDto';
import { CatalogueL1EntityDto } from '../../models/CatalogueL1EntityDto';
import { CatalogueL2EntityDto } from '../../models/CatalogueL2EntityDto';
import { AdminDashboardService } from '../../services/admin-dashboard.service';
import { UserService } from '../../services/user.service';
import { AdminUserPrivilege } from '../../enums/AdminUserPrivilege';
import { CatalogueService } from '../../services/catalogue.service';
import { UserUiDto } from '../../models/user/UserUiDto';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Store } from '@ngrx/store';
import { NavigationEventsDto } from '../../models/NavigationEventsDto';
import { NavigationActions } from '../../state-management/navigation/navigation.actions';
import { selectBreadcrumbs, selectNavigation } from '../../state-management/navigation/navigation.features';
import { selectUserUiDto } from '../../state-management/session.features';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.sass']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  userEntityDto?: UserUiDto

  selectedAuction?: AuctionEntityDto;
  selectedRfx?: RfxUiDto;
  selectedCatalogueL1?: CatalogueL1EntityDto
  selectedCatalogueL2?: CatalogueL2EntityDto

  isShowEventStagePage: boolean = false;
  pageName: string | null = ApplicationConstants.DASHBOARD_PAGE;

  updateBreadcrumbsSubscription$?: Subscription

  // catalogueL1?: string 
  // catalogueL2?: string

  constructor(
    private dashboardRedirectionService: DashboardRedirectionService,
    private adminSourcingEventsDataHolderService: AdminSourcingEventsDataHolderService,
    private catalogueService: CatalogueService,
    private userService: UserService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.getUserUiDto()
    this.store.select(selectBreadcrumbs).subscribe(data => {
      if (data) {
        let breadcrumbs = ApplicationUtils.deepClone(data);
        console.log(`breadcrumbs : ${JSON.stringify(breadcrumbs)}`);
      }
    })
    this.updateBreadcrumbsSubscription$ = this.store.select(selectNavigation).subscribe(navigation => {
      if (navigation) {
        this.pageName = navigation.pageName!;//sessionStorage.getItem('PAGE_NAME');

        if (this.pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE ||
          this.pageName == ApplicationConstants.ADMIN_LINE_CATALOGUE_PAGE ||
          this.pageName == ApplicationConstants.ADMIN_CATALOGUE_REQUISITION_PAGE
        ) {
          this.selectedCatalogueL1 = this.catalogueService.selectedCatalogueL1;
          this.selectedCatalogueL2 = this.catalogueService.selectedCatalogueL2;
        }

        if (this.pageName == 'AUCTION_CREATOR_PAGE') {
          this.selectedAuction = this.adminSourcingEventsDataHolderService.getSelectedAuction();
        }

        if (this.pageName == 'RFX_CREATOR_PAGE') {
          this.selectedRfx = this.adminSourcingEventsDataHolderService.getSelectedRfx();
        }
      }
    })
  }

  async getUserUiDto() {
    this.userEntityDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  navigateToPage(pageName: string) {
    const navigation = new NavigationEventsDto();
    navigation.pageName = pageName;

    if (pageName == ApplicationConstants.ADMIN_CATALOGUE_PAGE) {
      this.catalogueService.updateSelectedCatalogueL1EntityDto(undefined);
      this.catalogueService.updateSelectedCatalogueL2EntityDto(undefined);
    }

    if (ApplicationUtils.isSuperAdmin(this.userEntityDto!)) {
      // this.dashboardRedirectionService.storeNavigationContext(
      //   ApplicationConstants.SUPERADMIN, pageName
      // );

      navigation.contextName = ApplicationConstants.SUPERADMIN;
    } else {
      // this.dashboardRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN, pageName
      // );

      navigation.contextName = ApplicationConstants.ADMIN;
    }

    // this.dashboardRedirectionService.setUpdateBreadcrumbs(true);

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }

  navigateToSuperAdminPage() {
    // this.dashboardRedirectionService.storeNavigationContext(
    //   ApplicationConstants.SUPERADMIN,
    //   ApplicationConstants.ORGANIZATION_PAGE
    // );
    // this.dashboardRedirectionService.setUpdateBreadcrumbs(true);

    const navigation = new NavigationEventsDto();
    navigation.contextName = ApplicationConstants.SUPERADMIN;
    navigation.pageName = ApplicationConstants.ORGANIZATION_PAGE;

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }

  navigateToCatalogueL1() {
    const navigation = new NavigationEventsDto();
    navigation.pageName = ApplicationConstants.AUCTION_CREATOR_PAGE;

    this.catalogueService.updateSelectedCatalogueL2EntityDto(undefined);

    let userPrivilegeList 

    if (true) {
      // this.dashboardRedirectionService.storeNavigationContext(
      //   ApplicationConstants.SUPERADMIN,
      //   ApplicationConstants.ADMIN_CATALOGUE_PAGE
      // );

      navigation.contextName = ApplicationConstants.SUPERADMIN;
    } else {
      // this.dashboardRedirectionService.storeNavigationContext(
      //   ApplicationConstants.ADMIN,
      //   ApplicationConstants.ADMIN_CATALOGUE_PAGE
      // );

      navigation.contextName = ApplicationConstants.ADMIN;
    }

    // this.dashboardRedirectionService.setUpdateBreadcrumbs(true);

    this.store.dispatch(NavigationActions.storeNavigationContext({ navigation }));
  }
  

  ngOnDestroy(): void {
    if (this.updateBreadcrumbsSubscription$) {
      this.updateBreadcrumbsSubscription$.unsubscribe();
    }
  }
}
