import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { interval, Subscription, takeWhile } from 'rxjs';
import { selectOrganizationUiDto } from '../../state-management/onboarding/onboarding.features';
import { LoaderComponent } from '../loader/loader.component';
import { FormControl, Validators } from '@angular/forms';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { PrivacyAndTermsOfUseDto } from '../../models/PrivacyAndTermsOfUseDto';
import { Pattern } from '../../util/Patterns';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { UserUiDto } from '../../models/user/UserUiDto';
import { SessionActions } from '../../state-management/session.actions';
import { MessageService } from 'primeng/api';
import { VerifyOtpWorkflowEnum } from '../../enums/VerifyOtpWorkflowEnum';

@Component({
  selector: 'app-generate-verify-otp',
  templateUrl: './generate-verify-otp.component.html',
  styleUrls: ['./generate-verify-otp.component.sass']
})
export class GenerateVerifyOtpComponent implements OnInit, AfterViewInit, OnDestroy {
  currentView: 'GENERATE_OTP' | 'VERIFY_OTP' = 'GENERATE_OTP';

  ctrlEmail: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]);
  ctrlOtp: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.onlyNumber)]);

  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  organizationUiDto?: OrganizationWrapperUiDto

  isLoading: boolean = false;
  isCountdownRunning: boolean = false
  countdown: number = 0;
  noOfResendCount: number = 0;
  disableResendButton: boolean = false;
  otpId?: string;

  isShowPrivacyDialog: boolean = false;
  isShowTermsDialog: boolean = false;

  otpWorkflow: VerifyOtpWorkflowEnum = VerifyOtpWorkflowEnum.SIMPLE_WORKFLOW;

  organizationSubscription$?: Subscription;

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  constructor(
    private _router: Router,
    private store: Store,
    private userService: UserService,
    private authService: AuthenticationService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.organizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;

        if (this.organizationUiDto) {
          if (this.organizationUiDto.privacyAndTermsOfUseList) {
            this.privacyAndTermsOfUseDto = this.organizationUiDto.privacyAndTermsOfUseList.find(item => item.footerPolicy);
          }
        }
      }
    })
  }

  ngAfterViewInit(): void {
    this.otpWorkflow = this.userService.getOtpWorkflow;
  }

  startCountdown(): void {
    this.isCountdownRunning = true;
    this.countdown = 30;
    interval(1000).pipe(
      takeWhile(() => this.countdown > 0),
    ).subscribe(() => {
      this.countdown--;
      if (this.countdown === 0) {
        this.isCountdownRunning = false;
      }
    });
  }

  generateOTP() {
    if (this.ctrlEmail.invalid) {
      this.ctrlEmail.markAsTouched()
      return;
    }

    this.isLoading = true;

    this.startCountdown();
    this.noOfResendCount++
    this.userService.generateOTP(this.ctrlEmail.value, this.ctrlEmail.value, 'LOGIN').subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this.isLoading = false;
          this.currentView = 'VERIFY_OTP';
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
          this.isLoading = false;
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Error While OTP Generate" });
        this.isLoading = false;
      }
    })
  }

  onClickResendOtp(): void {
    if (!this.disableResendButton) {
      if (this.noOfResendCount >= 2) {
        this.disableResendButton = true;
      }

      this.resendOTP();
    }
  }

  resendOTP() {
    if (this.ctrlEmail.invalid) {
      this.ctrlEmail.markAllAsTouched()
      return;
    }

    this.startCountdown();
    this.noOfResendCount++
    this.userService.resendOTP(this.ctrlEmail.value, this.otpWorkflow, this.otpId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.otpId = apiResponseDto.data as string;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: "OTP sent successfully."});
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Error While OTP Generate" });
      }
    })
  }

  onClickVerifyOtp() {
    if (this.otpWorkflow == VerifyOtpWorkflowEnum.LOGIN_OTP_WORKFLOW) {
      this.loginWithOtp();
    } else {
      this.verifyOtp();
    }
  }

  verifyOtp() {
    if (this.ctrlOtp.invalid) {
      this.ctrlOtp.markAllAsTouched()
      return;
    }

    this.isLoading = true

    let userEnteredOtp = this.ctrlOtp.value;

    this.userService.verifyOTP(this.otpId as string, userEnteredOtp).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let userUiDto = apiResponseDto.data as UserUiDto;
          this.store.dispatch(SessionActions.updateUserUiDto({ userUiDto }));
          this.messageService.add({ severity: 'success', summary: 'Success', detail: "Successfully Verified" });
          this.isLoading = false

          setTimeout(() => {
            // 
          }, 2000);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
          this.isLoading = false
        }
      },
      error: (err) => {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: "Error While verifying OTP" });
        this.isLoading = false
      }
    })
  }

  loginWithOtp() {
    if (this.ctrlOtp.invalid) {
      this.ctrlOtp.markAsTouched()
      return;
    }

    this.appLoader?.openLoaderIcon(ApplicationConstants.USER_REGISTRATION_PROGRESS, "Please wait logging in...");
    let emailId = this.ctrlEmail.value;
    let userOtp = this.ctrlOtp.value;
    this.authService.doAuthentication(emailId!, userOtp!, this.appLoader!, this.otpId);
  }

  openTermsDialog() {
    this.isShowTermsDialog = true;
  }

  openPrivacyDialog() {
    this.isShowPrivacyDialog = true;
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  navigateToLoginPage() {
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/"], { skipLocationChange: true }))
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe()
    }
  }
}
