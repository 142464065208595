  <div class="bg-blue-50 h-screen">
    <div class="px-8 py-12 ">
      <div class="flex justify-center items-center mb-6">
        <div class="text-2xl text-gray-800 font-bold w-96 text-center flex justify-center items-center">
          
            <img src="assets/images/congratulation.svg" alt="">
          
        </div>
      </div>
      <div class="flex justify-center items-center mb-8 ">
        <div class="text-2xl text-gray-800 font-bold  text-center w-[452px]">
          <div class="mb-8"> <span>Congratulations</span></div>
          
          <div class="text-sm font-medium text-gray-700 mb-8">
            Password has been successfully set
           </div>
           <div class="flex justify-center items-center mb-8">
            <p-button styleClass="px-3 py-1.5"  class="text-center text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm"
            (onClick)="navigateToLoginPage()">
              Login now
            </p-button>
          </div>

        </div>
      </div>
      <!-- <div class="flex justify-center items-center mb-6">
       
      </div> -->
  
      
  
      <div class="flex justify-center items-center mt-40">
        <div class="w-96 text-center text-xs text-gray-700 font-medium">
          Copyright 2024 Procurext - All rights reserved.
           <span class="underline cursor-pointer" (click)="openTermsDialog()">Terms of Service</span> and <span class="underline cursor-pointer" (click)="openPrivacyDialog()">
            Privacy Policy
          </span>
        </div>
      </div>
    </div>
  
  </div>

  
<p-dialog [style]="{width: '50vw'}" [(visible)]="isShowPrivacyDialog" [modal]="true" [draggable]="false" header="PRIVACY POLICY">
  <div class="scroll-container">
    <div #privacyHeader></div>
    <div class="m-3" style="white-space: normal;">
      <p [innerHTML]="privacyAndTermsOfUseDto?.privacyPolicy ?? '' | safe: 'html'"></p>
    </div>
  </div>
  <p-button styleClass="p-2" 
  class="text-center items-center bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm inline-flex"(onClick)="moveToTop(privacyHeader)">
    <i-lucide name="arrow-up" [size]="24" class="my-icon"></i-lucide>
  </p-button>
</p-dialog>

<p-dialog [style]="{width: '50vw'}" [(visible)]="isShowTermsDialog" [modal]="true" [draggable]="false" header="TERMS OF USE">
  <div class="scroll-container">
    <div #termsHeader></div>
    <div class="m-3" style="white-space: normal;">
      <p [innerHTML]="privacyAndTermsOfUseDto?.termsOfUse ?? '' | safe: 'html'"></p>
    </div>
    <p-button styleClass="p-2"
    class="text-center items-center bg-blue-700 text-white border-1 border-blue-300 hover:border-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm inline-flex" (onClick)="moveToTop(termsHeader)">
      <i-lucide name="arrow-up" [size]="24" class="my-icon"></i-lucide>
    </p-button>
  </div>
</p-dialog>