<div class="bg-blue-50 h-screen flex items-center justify-center">
  <p-progressSpinner [style]="{height: '90px', width: '90px'}" *ngIf="!isLinkExpired">
  </p-progressSpinner>

  

  <div class="flex justify-center items-center mb-6" *ngIf="isLinkExpired">
    <div class=" bg-white rounded-lg px-4 py-8 w-96 h-52">
      <div class="flex justify-center item-center my-5">
        <p class="text-red-500 font-semibold text-base text-center">{{errorMessage}}</p>
      </div>
      <div class="text-sm text-center text-gray-800 font-medium underline cursor-pointer mt-3" routerLink="/">
        <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block" style="color: black;"></i-lucide> Back to
        login
      </div>
    </div>
  </div>

  
<!-- 
  <div class=" flex justify-center items-center mb-6 " *ngIf="isLinkExpired">
    <div>
      <p class="text-red">{{errorMessage}}</p>
    </div>

    <div class="text-sm text-gray-800 font-medium underline cursor-pointer mt-16" routerLink="/">
      <i-lucide name="arrow-left" [size]="18" class="my-icon inline-block" style="color: black;"></i-lucide> Back to
      login
    </div>
  </div> -->

</div>