<div>
  <div class="mb-3">
    {{message || 'Are you sure?'}}
  </div>
  <div class="text-end gap-2 ">
    <p-button label="Cancel" (onClick)="onClickReject()"
      class="text-center basis-1/2 text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-gray-300 font-medium rounded text-sm px-5 py-1.5 me-3"></p-button>
    <p-button label="Ok" (onClick)="onClickAccept()"
      class="text-center basis-1/2 text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:ring-blue-300 font-medium rounded text-sm px-5 py-1.5"></p-button>
  </div>
</div>