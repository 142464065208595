import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryCodeDto } from '../../models/CountryCodeDto';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { selectUserUiDto } from '../../state-management/session.features';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { Pattern } from '../../util/Patterns';
import { MessageService } from 'primeng/api';
import { UserUiDto } from '../../models/user/UserUiDto';
import { MobileWrapper } from '../../models/user/MobileWrapper';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { UserService } from '../../services/user.service';
import { SessionActions } from '../../state-management/session.actions';
import { ApplicationConstants } from '../../util/ApplicationConstants';

@Component({
  selector: 'app-basic-details',
  templateUrl: './basic-details.component.html',
  styleUrls: ['./basic-details.component.sass']
})
export class BasicDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onNext = new EventEmitter<void>();

  formGroup: FormGroup;

  userUiDto?: UserUiDto;

  isLoading: boolean = false;
  isSuperAdmin: boolean = false;
  selectedCountryCode = CountryCodeDto.default();

  countryCodeForMobileNo$ = new BehaviorSubject<CountryCodeDto>(CountryCodeDto.default());

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private messageService: MessageService
  ) {
    this.formGroup = this.formBuilder.group({
      firstName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      lastName: new FormControl('', [Validators.required, Validators.maxLength(100)]),
      orgDesignation: new FormControl('', [Validators.required, Validators.maxLength(250)]),
      mobileNo: new FormControl('', [Validators.required, Validators.pattern(Pattern.mobile)]),
    });
  }

  ngOnInit(): void {
    this.formGroup.reset();
    this.loadUserUiDto();
  }

  ngAfterViewInit(): void {
    
  }

  get fc() { return this.formGroup.controls; }

  async loadUserUiDto() {
    this.userUiDto = await firstValueFrom(this.store.pipe(select(selectUserUiDto)));
    this.userUiDto = ApplicationUtils.deepClone(this.userUiDto);
    this.isSuperAdmin = ApplicationUtils.isSuperAdmin(this.userUiDto!);
    this.populateBasicDetails();
  }

  populateBasicDetails() {
    this.formGroup.controls['firstName'].patchValue(this.userUiDto?.firstName);
    this.formGroup.controls['lastName'].patchValue(this.userUiDto?.lastName);
    this.formGroup.controls['orgDesignation'].patchValue(this.userUiDto?.orgDesignation);

    let mobileWrapper = this.userUiDto?.mobileWrapper;
    this.formGroup.controls['mobileNo'].patchValue(mobileWrapper?.mobileNo);

    this.handleCountryCodeEvent(mobileWrapper?.countryCode ?? CountryCodeDto.default());
  }

  handleCountryCodeEvent(countryCodeDto: CountryCodeDto) {
    this.selectedCountryCode = countryCodeDto;
    this.countryCodeForMobileNo$.next(countryCodeDto)

    let mobileNoLength = parseInt(countryCodeDto.mobileNoLength!);
    let pattern = "^[0-9]{" + mobileNoLength + "}"

    this.formGroup.controls['mobileNo'].clearValidators();
    this.formGroup.controls['mobileNo'].setValidators([Validators.required, Validators.pattern(pattern)]);
    this.formGroup.controls['mobileNo'].updateValueAndValidity();
  }

  mergeUserUiDto() {
    let userUiDto: UserUiDto = ApplicationUtils.clone(this.userUiDto);

    userUiDto.firstName = this.formGroup.controls['firstName'].value;
    userUiDto.lastName = this.formGroup.controls['lastName'].value;
    userUiDto.orgDesignation = this.formGroup.controls['orgDesignation'].value;

    let mobileWrapper = new MobileWrapper();
    if (userUiDto.mobileWrapper) {
      mobileWrapper = userUiDto!.mobileWrapper;
    }

    mobileWrapper.mobileNo = this.formGroup.controls['mobileNo'].value;
    mobileWrapper.countryCode = this.selectedCountryCode;
    mobileWrapper.primary = true;

    // Merge mobileWrapper to userUiDto
    userUiDto.mobileWrapper = mobileWrapper;

    return userUiDto;
  }

  saveUserProfile() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.isLoading = true;

    let userEntityDto = this.mergeUserUiDto();

    this.userService.updateUserDetails(userEntityDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isLoading = false;

        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let userUiDto = apiResponseDto.data as UserUiDto;
          this.store.dispatch(SessionActions.updateUserUiDto({ userUiDto }));
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Details Saved Successfully' });

          setTimeout(() => {
            this.onNext.emit();
          }, 2000)
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message! });
        }
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating details' });
        this.isLoading = false;
      }
    })
  }

  ngOnDestroy(): void {

  }
}
