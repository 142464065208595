<div class="model-body">
  
    <app-user-profile *ngIf="!isProfileEditMode" [userDto]="userDto"
      (onEditProfile)="onEditProfileMode()"></app-user-profile>
    <div class="mb-2" *ngIf="userDto?.firstName && isProfileEditMode">
      <span> Welcome </span><span><strong>{{userDto?.firstName}}</strong></span>
    </div>
    <form class="needs-validation" novalidate [formGroup]="formGroup" (keyup.enter)="handleValidSubmit()"
      *ngIf="isProfileEditMode">
      <div class="model-body-top">
      <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
   
         <div class=" mb-2">
          <label class="text-gray-700  text-sm font-semibold leading-4">
            Email Id
          </label>
          <div class="">
            <input class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-100 text-sm" type="email"  id="primaryEmailId" 
            formControlName="primaryEmailId">
          </div>
         </div>
          <div class=" mb-2">
            <label for="firstName" class="text-gray-700  text-sm font-semibold leading-4">First Name</label>
            <input type = "text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-100 text-sm" 
            id="firstName" 
              formControlName="firstName">
            <div *ngIf="isSubmitted || fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
              <p class="text-red-500 " *ngIf="fc.firstName.errors?.required">Required</p>
            </div>
          </div>
          <div class=" mb-2">
            <label for="lastName" class="text-gray-700  text-sm font-semibold leading-4">Last Name</label>
            <input type = "text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-100 text-sm" 
            id="lastName" 
            formControlName="lastName">
            <div *ngIf="isSubmitted || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
              <p class="text-red-500 " *ngIf="fc.lastName.errors?.required">Required</p>
            </div>
          </div>
          <div class="mb-2">
            <label for="designation" class="text-gray-700  text-sm font-semibold leading-4">Designation (Optional)</label>
            <input tabindex="2" type="text" class="bg-white w-full rounded-md flex-col self-stretch border border-solid border-gray-300  h-8 px-2 py-0 text-gray-100 text-sm" 
            id="lastName" 
            formControlName="designation" id="designation">
          </div>

        <div class="mb-2 text-center">
          <img alt="" src="assets/images/avatar05.jpg" class="profile-image-class border" *ngIf="!imageUrl">
          <img [src]="imageUrl" alt="" class="profile-image-class" *ngIf="imageUrl">
          <div class="ms-2 mt-2">
            <input #profileImg name="profile_pic" onclick="this.value=null;" type="file" class="hidden"
              (change)="chooseFile($event)" />
            <button class="btn btn-link" *ngIf="!this.userId" (click)="profileImg.click()">Change</button>
          </div>
        </div>
        <div class="col-12  ">
          <img alt="" src="assets/images/avatar05.jpg" class="profile-image-class border" *ngIf="!imageUrl">
          <img [src]="imageUrl" alt="" class="profile-image-class" *ngIf="imageUrl">
          <div class=" mt-2 ms-3">
            <input #profileImg name="profile_pic" onclick="this.value=null;" type="file" class="hidden"
              (change)="chooseFile($event)" />
            <button class="btn btn-link" *ngIf="!this.userId" (click)="profileImg.click()">Change</button>
          </div>
        </div>
        <!-- <div class="col-12 d-block d-md-none">


          <div class="form-floating mb-2">
            <input tabindex="4" type="text" class="form-control" id="primaryEmailId" placeholder="Email Id"
              formControlName="primaryEmailId">
            <label for="primaryEmailId">Email Id</label>
          </div>

          <div class="form-floating mb-2">
            <input tabindex="1" type="text" class="form-control" id="firstName" placeholder="First Name"
              formControlName="firstName">
            <label for="firstName">First Name</label>
            <div *ngIf="isSubmitted || fc.firstName.invalid && (fc.firstName.dirty || fc.firstName.touched)">
              <p class="text-red-500 " *ngIf="fc.firstName.errors?.required">Required</p>
            </div>
          </div>
          <div class="form-floating mb-2">
            <input tabindex="2" type="text" class="form-control" id="lastName" placeholder="Last Name"
              formControlName="lastName">
            <label for="lastName">Last Name</label>
            <div *ngIf="isSubmitted || fc.lastName.invalid && (fc.lastName.dirty || fc.lastName.touched)">
              <p class="text-red-500 " *ngIf="fc.lastName.errors?.required">Required</p>
            </div>
          </div>
          <div class="form-floating mb-2">
            <input tabindex="2" type="text" class="form-control" id="designation" placeholder="Designation"
              formControlName="designation">
            <label for="designation">Designation (Optional)</label>

          </div>

        </div> -->
      </div>

      <div class="my-3">
        <app-message-toast *ngIf="_showErrorToastProfile$ | async" [isError]="true"
          [message]="errorMsg"></app-message-toast>
        <app-message-toast *ngIf="_showSuccessToastProfile$ | async" [isError]="false"
          [message]="errorMsg"></app-message-toast>
      </div>

      <div class="d-grid gap-2 my-3" *ngIf="isUserProfileLoading">
        <div class="e-spinner center-spinner" style="margin-top: -20px !important"></div>
      </div>
    </div>
      <div *ngIf="!userId">
        <div class="col-12 text-end" *ngIf="(!isUserProfileLoading && !(_showSuccessToastProfile$ | async))">
          <button type="button"
            class="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            (click)="toggleEditProfileMode()">Cancel</button>
          <button type="button"
            class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            (click)="handleValidSubmit()">Save</button>
        </div>
      </div>
    </form>
    
      <div class="col-12 mt-3" *ngIf="!isProfileEditMode">
        <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-2">
          <div class="">
            <div class="border p-2 bg-white">
              <div class="flex flex-row">
                <div class="basis-1/2">
                  <h6 class="heading-h5">Email</h6>
                </div>
                <div class="basis-1/2">

                  <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-class"
                    (click)="openEmailModal(emailModal)">
                </div>
              </div>
              <hr class="hr my-2">

              <div class="mt-3 mx-2">
                <!-- <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of userDto?.emailList;">
                  <div class="row my-3">
                    <div class="div-class col-8 ps-0">
                      {{item.email}}
                    </div>
                    <div class="col-4 text-end" *ngIf="item.primary">
                      <span class="badge bg-secondary-common" > Primary</span>
                    </div>
                    <div class="col-4 text-end" *ngIf="!item.primary">
                      <!-- <img src="assets/icons/edit_light.svg" class=" c-pointer svg-icon-class me-3"> --
                      <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                        (click)="openDeleteConfirmModal(deleteConfirmationModal, item, 'EMAIL')">
                    </div>
                  </div>
                </li>
              </ul> -->
              </div>
            </div>
          </div>
          <div class="">
            <div class="border p-2 bg-white">
              <div class="flex flex-row">
                <div class="basis-1/2">
                  <h6 class="heading-h5">Contact</h6>
                </div>
                <div class="basis-1/2 text-end">

                  <img src="assets/icons/addnew_light.svg" class=" c-pointer svg-icon-class"
                    (click)="openContactModal(contactModal)">
                </div>
              </div>
              <hr class="hr my-2">
              <div class=" mt-3 mx-2">
                <!-- <ul style="list-style: none;" class="ps-2">
                <li *ngFor="let item of userDto?.mobileList;">
                  <div class="row my-3">
                    <div class="div-class col-8 ps-0">
                      {{getCountryCode(item.countryCode!)}} {{item.mobileNo}}

                    </div>

                    <div class="col-4 text-end" *ngIf="item.primary">
                      <span class="badge bg-secondary-common" > Primary</span>
                    </div>

                    <div class="col-4 text-end" *ngIf="!item.primary">
                      <img src="assets/icons/delete_danger.svg" class="svg-icon-class c-pointer"
                        (click)="openDeleteConfirmModal(deleteConfirmationModal, item, 'MOBILE')">
                    </div>
                  </div>
                </li>
              </ul> -->
              </div>
            </div>
          </div>
        </div>
      </div>
   
 
</div>

<ng-template #contactModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD CONTACT</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addMobileModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">

        <form class="needs-validation" novalidate (keyup.enter)="addContactNo()">
          <div class="input-group">
            <span class="input-group-addon">
              <app-country-code-select #countryCode (countryCodeEvent)="handleCountryCodeEvent($event)"
                [countryCode$]="countryCode$"></app-country-code-select>
            </span>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="ctrlMobileNo" placeholder="contact"
                [formControl]="ctrlMobileNo" />
              <label for="ctrlMobileNo">Contact No</label>
              <div *ngIf="ctrlMobileNo.invalid && (ctrlMobileNo.dirty || ctrlMobileNo.touched)">
                <p class="text-red-500 " *ngIf="ctrlMobileNo.errors?.['required']">Required</p>
                <p class="text-red-500 " *ngIf="ctrlMobileNo.errors?.['pattern']">Invalid</p>
              </div>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Contact number saved successfully.'"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="addContactNo()">ADD</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #emailModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>ADD EMAIL</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(addEmailModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">

        <form class="needs-validation" novalidate (keyup.enter)="otpId ? verifyEmailId() : addEmailId()">
          <div class="form-floating mb-3" *ngIf="!otpId">
            <input type="email" class="form-control" id="ctrlEmail" placeholder="name@example.com"
              [formControl]="ctrlEmail" />
            <label for="ctrlEmail">EMAIL ADDRESS</label>
            <div *ngIf="ctrlEmail.invalid && (ctrlEmail.dirty || ctrlEmail.touched)">
              <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['required']">Required</p>
              <p class="text-red-500 " *ngIf="ctrlEmail.errors?.['pattern']">Invalid</p>
            </div>
          </div>
          <div *ngIf="otpId" class="mb-2">
            Enter the One Time Password (OTP) which was just sent to the email address you registered with. Be sure to
            check your spam folder.
          </div>
          <div class="form-floating mb-3" *ngIf="otpId">
            <input type="email" class="form-control" id="ctrlOtp" placeholder="otp" [formControl]="ctrlOtp" />
            <label for="ctrlOtp">OTP</label>
            <div *ngIf="ctrlOtp.invalid && (ctrlOtp.dirty || ctrlOtp.touched)">
              <p class="text-red-500 " *ngIf="ctrlOtp.errors?.['required']">Required</p>
            </div>
          </div>

          <div class="my-3">
            <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true"
              [message]="errorMsg"></app-message-toast>
            <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
              [message]="'Email id saved successfully.'"></app-message-toast>
          </div>

          <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
            <div class="e-spinner"></div>
          </div>

          <div class="mb-3 col-12 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " *ngIf="!otpId" (click)="addEmailId()">ADD</button>
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" *ngIf="otpId"
              (click)="cancelEmailVerify()">CANCEL</button>
            <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " *ngIf="otpId"
              (click)="verifyEmailId()">VERIFY</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #deleteConfirmationModal>
  <div class="model-body">
    <header class="m-2">
      <div class="row justify-content-between">
        <div class="col-9 d-flex align-items-center">
          <h2>Delete {{selectedWrapperType == 'EMAIL' ? 'Email Id' : 'Mobile Number'}}</h2>
        </div>
        <div class="col-auto text-end">
          <img src="assets/icons/close_light.svg" class="close-icon-class c-pointer" ngbAutoFocus
            (click)="closeModal(deleteModalRef)">
        </div>
      </div>
    </header>
    <div class="page-divider"></div>
    <div class="model-body-top">
      <div class="m-3">
        <p>
          Are you sure you want to delete this {{selectedWrapperType == 'EMAIL' ? 'Email Id' : 'Contact'}}
        </p>

        <div class="my-3">
          <app-message-toast *ngIf="_showErrorToast$ |async" [isError]="true" [message]="errorMsg"></app-message-toast>
          <app-message-toast *ngIf="_showSuccessToast$ |async" [isError]="false"
            message="{{selectedWrapperType == 'EMAIL' ? 'Email' : 'Contact'}} deleted successfully."></app-message-toast>
        </div>

        <div class="d-grid gap-2 mb-3" *ngIf="isLoading">
          <div class="e-spinner"></div>
        </div>

        <div class="mb-3 text-end" *ngIf="!isLoading && !(_showSuccessToast$ | async)">
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 me-3" (click)="closeModal(deleteModalRef)">
            CANCEL
          </button>
          <button type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2  me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 " (click)="deleteEmailOrMobile()">
            OK
          </button>
        </div>

      </div>
    </div>
  </div>
</ng-template>