import { RedirectLoaderComponent } from './shared/components/redirect-loader/redirect-loader.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './common/page-not-found/page-not-found.component';

import { ComingSoonComponent } from './shared/components/coming-soon/coming-soon.component';
import { AdminGenerateGraphComponent } from './layouts/admin/admin-generate-graph/admin-generate-graph.component';

import { UnsubscribeNotificationComponent } from './app/shared/components/unsubscribe-notification/unsubscribe-notification.component';
import { EmailTemplateConfigComponent } from './layouts/sadmin/email-template-config/email-template-config.component';


const routes: Routes = [
  {
    path: 'SuperAdmin',
    loadChildren: () => import('./shared/routing-module/super-admin/super-admin.module').then(m => m.SuperAdminModule)
  },
  {
    path: 'Admin',
    loadChildren: () => import('./shared/routing-module/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '',
    loadChildren: () => import('./shared/routing-module/landing/landing.module').then(m => m.LandingModule)
  },
  { path: 'coming-soon', component: ComingSoonComponent },
  { path: 'generate-graph', component: AdminGenerateGraphComponent },
  { path: 'redirect-loader', component: RedirectLoaderComponent },
  { path: 'email-template-config', component: EmailTemplateConfigComponent },
  { path: 'unsubscribe', component: UnsubscribeNotificationComponent },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  PageNotFoundComponent,
]
