<p-dropdown [options]="countries" [(ngModel)]="selectedCountry" optionLabel="countryName" [filter]="true"
  filterBy="countryName" [showClear]="false" (onChange)="handleCountySelection($event)" styleClass="px-2 border border-gray-300 h-8"
  appendTo="body" [resetFilterOnHide]="true">
  <ng-template pTemplate="selectedItem">
    <div class="flex align-items-center gap-2" *ngIf="selectedCountry">
      <img src="/assets/images/flag_placeholder.png" [class]="'fi fi-' + selectedCountry.countryFlag" class="absolute top-2"
        style="width: 18px , " />
      <div class="pl-6 text-xs top-2 absolute ps-7">{{ selectedCountry.countryCode }}</div>
    </div>
  </ng-template>
  <ng-template let-country pTemplate="item">
    <div class="flex align-items-center gap-2">
      <img src="/assets/images/flag_placeholder.png" [class]="'fi fi-' + country.countryFlag" style="width: 18px" />
      <div>{{ country.countryName }}</div>
    </div>
  </ng-template>
</p-dropdown>