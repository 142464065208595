import { createFeature, createReducer, createSelector, MemoizedSelector, on } from "@ngrx/store";
import { OrgOrganogramUiDto } from "../../models/OrgOrganogramUiDto";
import { OnboardingActions } from "./onboarding.actions";
import { CompanyOrganogramUiDto } from "../../models/CompanyOrganogramUiDto";
import { PlantOrganogramUiDto } from "../../models/PlantOrganogramUiDto";
import { OrganizationWrapperUiDto } from "../../models/OrganizationWrapperUiDto";
import { selectTenderManagementFeatureState } from "../tender/tender.features";

interface State {
  orgOrganogramUiDto: OrgOrganogramUiDto | null;
  companyCode: string | null;
  plantCode: string | null;
}

const initialState: State = {
  orgOrganogramUiDto: null,
  companyCode: null,
  plantCode: null
};

export const onboardingFeature = createFeature({
  name: 'onboarding',
  reducer: createReducer(
    initialState,
    on(OnboardingActions.saveOrgOrganogram, (state, { orgOrganogramUiDto }) => ({
      ...state,
      orgOrganogramUiDto,
    })),
    on(OnboardingActions.getOrgOrganogram, (state) => ({
      ...state,
    })),
    on(OnboardingActions.clearOrgOrganogram, (state) => ({
      ...state,
      orgOrganogramUiDto: null,
    })),

    // Update Organization
    on(OnboardingActions.updateOrganizationUiDto, (state, { organizationWrapperUiDto }) => ({
      ...state,
      orgOrganogramUiDto: {
        ...state.orgOrganogramUiDto,
        organizationWrapperUiDto: organizationWrapperUiDto
      }
    })),
  ),
  extraSelectors: ({ selectOrgOrganogramUiDto, selectCompanyCode, selectPlantCode }) => {
    const selectOrganizationUiDto = createSelector(
      selectOrgOrganogramUiDto,
      (orgOrganogramUiDto: OrgOrganogramUiDto | null) =>
        orgOrganogramUiDto?.organizationWrapperUiDto || null
    );

    const selectCompanyByCode = createSelector(
      selectOrgOrganogramUiDto,
      selectCompanyCode,
      (orgOrganogramUiDto, companyCode) =>
        orgOrganogramUiDto?.companyUiDtos?.find(
          (company) => company.companyCode === companyCode
        ) || null
    );

    const selectPlantByCompanyAndPlantCode = createSelector(
      selectCompanyByCode,
      selectPlantCode,
      (company, plantCode) =>
        company?.plantUiDtos?.find((plant) => plant.plantCode === plantCode) ||
        null
    );

    return {
      selectOrganizationUiDto,
      selectCompanyByCode,
      selectPlantByCompanyAndPlantCode,
    };
  },
});

export const {
  name,
  reducer,
  selectOnboardingState,
  selectOrgOrganogramUiDto,
  selectCompanyByCode,
  selectPlantByCompanyAndPlantCode,
  selectOrganizationUiDto
} = onboardingFeature