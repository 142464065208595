import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { OrganizationWrapperUiDto } from 'src/app/shared/models/OrganizationWrapperUiDto';
import { PrivacyAndTermsOfUseDto } from 'src/app/shared/models/PrivacyAndTermsOfUseDto';
import { ServerAPIResponseDto } from 'src/app/shared/models/ServerAPIResponseDto';
import { UserService } from 'src/app/shared/services/user.service';
import { selectOrganizationUiDto } from 'src/app/shared/state-management/onboarding/onboarding.features';
import { ApplicationConstants } from 'src/app/shared/util/ApplicationConstants';
import { Pattern } from 'src/app/shared/util/Patterns';

@Component({
  selector: 'app-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.sass']
})
export class UserPasswordResetComponent implements OnInit, OnDestroy {
  ctrlEmail: FormControl = new FormControl('', [Validators.required, Validators.pattern(Pattern.email)]);

  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  organizationUiDto?: OrganizationWrapperUiDto

  isLoading: boolean = false;
  isShowPrivacyDialog: boolean = false;
  isShowTermsDialog: boolean = false;

  organizationSubscription$?: Subscription;

  constructor(
    private _router: Router,
    private store: Store,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.organizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;

        if (this.organizationUiDto) {
          if (this.organizationUiDto.privacyAndTermsOfUseList) {
            this.privacyAndTermsOfUseDto = this.organizationUiDto.privacyAndTermsOfUseList.find(item => item.footerPolicy);
          }
        }
      }
    })
  }

  sendResetPasswordLink() {
    if (this.ctrlEmail.invalid) {
      this.ctrlEmail.markAsTouched()
      return;
    }

    this.isLoading = true;

    this.userService.sendResetPasswordLink(this.ctrlEmail.value).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: apiResponseDto.message });
          this.isLoading = false;
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
          this.isLoading = false;
        }
      },
      error: (err) => {
        console.error(err);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Error while sending reset password email!" });
        this.isLoading = false;
      }
    })
  }

  openTermsDialog() {
    this.isShowTermsDialog = true;
  }

  openPrivacyDialog() {
    this.isShowPrivacyDialog = true;
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  navigateToLoginPage() {
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/"], { skipLocationChange: true }))
  }

  ngOnDestroy(): void {
    this.isLoading = false;
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe()
    }
  }

}
