export class DeploymentConfigurationDto {
  singleLotAllowed?: boolean;
  multiLotAllowed?: boolean;
  multiAuctionHouseAllowed?: boolean;
  enableBlockchain?: boolean;
  enableAutoSeller?: boolean;
  enableClerkDashboard?: boolean;

  nodeServerUrl?: string
}
