import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PrivacyAndTermsOfUseDto } from '../../models/PrivacyAndTermsOfUseDto';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LoaderComponent } from '../loader/loader.component';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { UserService } from '../../services/user.service';
import { selectOrganizationUiDto } from '../../state-management/onboarding/onboarding.features';
import { FormControl, Validators } from '@angular/forms';
import { PasswordStrengthValidator } from '../../validators/password-strength.validators';
import { MessageService } from 'primeng/api';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { SessionActions } from '../../state-management/session.actions';
import { UserUiDto } from '../../models/user/UserUiDto';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoading: boolean = false;

  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  organizationUiDto?: OrganizationWrapperUiDto

  ctrlPassword: FormControl = new FormControl('', [Validators.required, Validators.minLength(12), PasswordStrengthValidator]);
  ctrlConfirmPassword: FormControl = new FormControl('', Validators.required);

  passwordLength: number = 0;

  isShowPrivacyDialog: boolean = false;
  isShowTermsDialog: boolean = false;
  isPasswordVisible: boolean = false;
  isRepeatPasswordVisible: boolean = false;

  emailVerificationId: string = "";
  emailForOnboarding: string = "";

  _isPasswordMatch$ = new BehaviorSubject<boolean>(false);

  organizationSubscription$?: Subscription;

  @ViewChild('appLoader', { static: false }) appLoader?: LoaderComponent;

  constructor(
    private _router: Router,
    private store: Store,
    private userService: UserService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.organizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;

        if (this.organizationUiDto) {
          if (this.organizationUiDto.privacyAndTermsOfUseList) {
            this.privacyAndTermsOfUseDto = this.organizationUiDto.privacyAndTermsOfUseList.find(item => item.footerPolicy);
          }
        }
      }
    })

    this.ctrlPassword.valueChanges.subscribe(val => {
      if (val && val.length > 0) {
        this.passwordLength = val.length;
      } else {
        this.passwordLength = 0;
      }
    })
  }

  ngAfterViewInit(): void {
    this.emailForOnboarding = this.userService.getEmailForOnboarding || '';
    this.emailVerificationId = this.userService.emailVerificationId || '';

    this.cdRef.detectChanges();
  }

  togglePasswordVisibility() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleRepeatPasswordVisibility() {
    this.isRepeatPasswordVisible = !this.isRepeatPasswordVisible;
  }

  onChange() {
    if (this.ctrlPassword.value == this.ctrlConfirmPassword.value) {
      this._isPasswordMatch$.next(true);
    } else {
      this._isPasswordMatch$.next(false);
    }
  }

  openTermsDialog() {
    this.isShowTermsDialog = true;
  }

  openPrivacyDialog() {
    this.isShowPrivacyDialog = true;
  }

  changeUserPassword() {
    if (this.ctrlPassword.invalid || this.ctrlConfirmPassword.invalid) {
      this.ctrlPassword.markAllAsTouched();
      this.ctrlConfirmPassword.markAllAsTouched();
      return;
    }

    if (this.ctrlPassword.value != this.ctrlConfirmPassword.value) {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: "Password Mismatch" });
      return;
    }

    this.isLoading = true;

    this.userService.changePassword(this.emailVerificationId, this.ctrlPassword.value).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.isLoading = false;
          this.messageService.add({ severity: 'success', summary: 'Success', detail: "Password changed successfully." });

          let userUiDto = apiResponseDto.data as UserUiDto;
          this.store.dispatch(SessionActions.updateUserUiDto({ userUiDto }));

          setTimeout(() => {
            this.navigateToCongratulation();
          }, 2000);
        } else {
          this.isLoading = false;
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
        }
      },
      error: (err: any) => {
        this.isLoading = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Error while changing password" });
      }
    })
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  navigateToLoginPage() {
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/"], { skipLocationChange: true }))
  }

  navigateToCongratulation() {
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/congratulation"], { skipLocationChange: true }))
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe()
    }
  }
}
