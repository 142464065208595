import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { UserUiDto } from '../../models/user/UserUiDto';
import { SessionActions } from '../../state-management/session.actions';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-pre-onboarding',
  templateUrl: './pre-onboarding.component.html',
  styleUrls: ['./pre-onboarding.component.sass']
})
export class PreOnboardingComponent implements OnInit, OnDestroy {
  isLinkExpired: boolean = false;
  errorMessage?: string;
  emailVerificationId: string = "";

  constructor(
    private store: Store,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      if (data && data['id']) {
        this.emailVerificationId = data['id'];
        this.checkOnboardingUser();
      } else {
        this.isLinkExpired = true;
      }
    })
  }

  checkOnboardingUser() {
    this.userService.checkOnboardEmail(this.emailVerificationId).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let emailId = apiResponseDto.data as string;

          this.userService.setEmailForOnboarding(emailId);
          this.userService.setEmailVerificationId(this.emailVerificationId);

          const url = new URL(window.location.href);
          const params = new URLSearchParams(url.search);

          params.delete('p');
          url.search = params.toString();
          window.history.replaceState({}, '', url.href);

          setTimeout(() => {
            this.router.navigate(['/changePassword'], { skipLocationChange: true, queryParamsHandling: 'merge' });
          }, 1000);
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message });
          this.isLinkExpired = true;
          this.errorMessage = apiResponseDto.message;
        }
      },
      error: (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: "Verification link is invalid!" });
        this.isLinkExpired = true;
        this.errorMessage = "Verification link is invalid!";
      }
    })
  }

  ngOnDestroy(): void {

  }
}
