import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.sass']
})
export class ConfirmationDialogComponent {
  @Input() message!: string;
  @Output() onClose = new EventEmitter<string>();

  onClickAccept() {
    this.onClose.emit('accept');
  }

  onClickReject() {
    this.onClose.emit('reject');
  }
}
