import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UserUiDto } from '../../models/user/UserUiDto';
import { Store, select } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectUserUiDto } from '../../state-management/session.features';
import { ApplicationUtils } from '../../util/ApplicationUtils';
import { ServerAPIResponseDto } from '../../models/ServerAPIResponseDto';
import { ApplicationConstants } from '../../util/ApplicationConstants';
import { MessageService } from 'primeng/api';
import { UserService } from '../../services/user.service';
import { DscTransactionDto } from '../../models/DscTransactionDto';
import { DscCallFromEnum } from '../../enums/DscCallFromEnum';
import { DscNotificationEnum } from '../../enums/DscNotificationEnum';
import { DscCertificateDto } from '../../models/user/DscCertificateDto';

@Component({
  selector: 'app-dsc-page',
  templateUrl: './dsc-page.component.html',
  styleUrls: ['./dsc-page.component.sass']
})
export class DscPageComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() onNext = new EventEmitter<void>();
  @Output() onPrevious = new EventEmitter<void>();

  userUiDto?: UserUiDto;

  isLoading: boolean = false;
  isAddDscLoading: boolean = false;
  isDeleteDscLoading: boolean = false;

  dscCertificateDtos: DscCertificateDto[] = []

  constructor(
    private store: Store,
    private userService: UserService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadUserUiDto();
  }

  ngAfterViewInit(): void {

  }

  async loadUserUiDto() {
    let userUiDto = await firstValueFrom(this.store.pipe(select(selectUserUiDto)));
    this.userUiDto = ApplicationUtils.deepClone(userUiDto);

    this.dscCertificateDtos = this.userUiDto?.dscCertificateDtos || []
  }

  mergeUserUiDto() {
    let userUiDto: UserUiDto = ApplicationUtils.clone(this.userUiDto);
    return userUiDto;
  }

  onClickBack() {
    this.onPrevious.emit();
  }

  addDscEvent() {
    this.isAddDscLoading = true;

    let dscTransactionDto = new DscTransactionDto();
    dscTransactionDto.userId = this.userUiDto?.userId;
    dscTransactionDto.orgCode = this.userUiDto?.orgCode;
    dscTransactionDto.firstName = this.userUiDto?.firstName;
    dscTransactionDto.lastName = this.userUiDto?.lastName;
    dscTransactionDto.emailId = this.userUiDto?.primaryEmailId;
    dscTransactionDto.workflowName = 'TransactionWorkflow';
    dscTransactionDto.action = DscNotificationEnum.CREATE;
    dscTransactionDto.callFrom = DscCallFromEnum.DSC_CLIENT_APP;


    this.userService.sendAddDscEvent(dscTransactionDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isAddDscLoading = false;

        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Dsc fetched successfully' });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message! });
        }
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while add dsc' });
        this.isAddDscLoading = false;
      }
    })
  }

  deleteDscTransactionEvent() {
    this.isDeleteDscLoading = true;

    let dscTransactionDto = new DscTransactionDto();
    dscTransactionDto.userId = this.userUiDto?.userId;
    dscTransactionDto.action = DscNotificationEnum.DSC_CONNECTION_DISCONNECT;
    dscTransactionDto.callFrom = DscCallFromEnum.DSC_CLIENT_APP;

    this.userService.sendDeleteDscEvent(dscTransactionDto).subscribe({
      next: (apiResponseDto: ServerAPIResponseDto) => {
        this.isDeleteDscLoading = false;

        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Dsc saved successfully' });
          this.onNext.emit();
        } else {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message! });
        }
      },
      error: (err) => {
        console.log(err);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while add dsc' });
        this.isDeleteDscLoading = false;
      }
    })

  }

  saveUserProfile() {
    this.onNext.emit();
    // this.isLoading = true;

    //  let userEntityDto = this.mergeUserUiDto();

    // this.userService.updateUserDetails(userEntityDto).subscribe({
    //   next: (apiResponseDto: ServerAPIResponseDto) => {
    //     this.isLoading = false;

    //     if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
    //       let userUiDto = apiResponseDto.data as UserUiDto;
    //       this.store.dispatch(SessionActions.updateUserUiDto({ userUiDto }));
    //       this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Details Saved Successfully' });

    //       setTimeout(() => {
    //         this.onNext.emit();
    //       }, 2000)
    //     } else {
    //       this.messageService.add({ severity: 'error', summary: 'Error', detail: apiResponseDto.message! });
    //     }
    //   },
    //   error: (err) => {
    //     console.log(err);
    //     this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Error while updating details' });
    //     this.isLoading = false;
    //   }
    // })
  }

  ngOnDestroy(): void {

  }
}
