import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApplicationUtils } from '../util/ApplicationUtils';
import { UserUiDto } from '../models/user/UserUiDto';

@Injectable({
  providedIn: 'root'
})
export class RoutingService {

  constructor(
    private router: Router
  ) { }

  doRouting(userUiDto: UserUiDto) {
    if (ApplicationUtils.isSuperAdmin(userUiDto)) {
      if (userUiDto?.firstName && userUiDto.lastName) {
        this.router.navigate(['SuperAdmin/Dashboard'], { skipLocationChange: true });
      } else {
        this.router.navigate(['/userOnboardingProfile'], { skipLocationChange: true });
      }
    } else {
      let dscCertificateDtos = userUiDto?.dscCertificateDtos || [];
      if (userUiDto?.firstName && userUiDto.lastName && dscCertificateDtos.length > 0) {
        this.router.navigate(['Admin/Dashboard'], { skipLocationChange: true });
      } else {
        this.router.navigate(['/userOnboardingProfile'], { skipLocationChange: true });
      }
    }
  }
}
