import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { OrganizationWrapperUiDto } from '../../models/OrganizationWrapperUiDto';
import { PrivacyAndTermsOfUseDto } from '../../models/PrivacyAndTermsOfUseDto';
import { selectOrganizationUiDto } from '../../state-management/onboarding/onboarding.features';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-congratulation-and-welcome-page',
  templateUrl: './congratulation-and-welcome-page.component.html',
  styleUrls: ['./congratulation-and-welcome-page.component.sass']
})
export class CongratulationAndWelcomePageComponent {
  privacyAndTermsOfUseDto?: PrivacyAndTermsOfUseDto;
  organizationUiDto?: OrganizationWrapperUiDto

  isShowPrivacyDialog: boolean = false;
  isShowTermsDialog: boolean = false;

  organizationSubscription$?: Subscription;

  constructor(
    private _router: Router,
    private store: Store,    
    private authService: AuthenticationService,
  ) { }
  
  ngOnInit(): void {
    this.organizationSubscription$ = this.store.pipe(select(selectOrganizationUiDto)).subscribe((data) => {
      if (data) {
        this.organizationUiDto = data;

        if (this.organizationUiDto) {
          if (this.organizationUiDto.privacyAndTermsOfUseList) {
            this.privacyAndTermsOfUseDto = this.organizationUiDto.privacyAndTermsOfUseList.find(item => item.footerPolicy);
          }
        }
      }
    })
  }

  moveToTop(ref: HTMLElement) {
    ref.scrollIntoView()
  }

  openTermsDialog() {
    this.isShowTermsDialog = true;
  }

  openPrivacyDialog() {
    this.isShowPrivacyDialog = true;
  }

  navigateToLoginPage() {
    this.authService.logout();
    this._router
      .navigateByUrl('/redirect-loader', { skipLocationChange: true })
      .then(() => this._router.navigate(["/"], { skipLocationChange: true }))
  }

  ngOnDestroy(): void {
    if (this.organizationSubscription$) {
      this.organizationSubscription$.unsubscribe()
    }
  }
}
