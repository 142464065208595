import { Injectable, OnDestroy } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { io, Socket } from 'socket.io-client';
import { UserUiDto } from '../models/user/UserUiDto';
import { firstValueFrom } from 'rxjs';
import { selectUserUiDto } from '../state-management/session.features';
import { CacheUpdateTypes } from '../util/CacheUpdateTypes';
import { TenderManagementActions } from '../state-management/tender/tender.actions';
import { TenderWrapperUiDto } from '../models/user/TenderWrapperUiDto';
import { OrganizationWrapperUiDto } from '../models/OrganizationWrapperUiDto';
import { OnboardingActions } from '../state-management/onboarding/onboarding.actions';
import { selectTenderWrapperUiDto } from '../state-management/tender/tender.features';
import { DscNotificationDto } from '../models/DscNotificationDto';
import { DscNotificationEnum } from '../enums/DscNotificationEnum';
import { UserService } from './user.service';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class SocketService implements OnDestroy {
  private socket?: Socket;
  // private readonly serverUrl = 'wss://dev-node.procurext.com'; // Replace with your server URL
  private reconnectInterval = 2000; // 2 seconds
  private heartbeatInterval?: any;

  userUiDto?: UserUiDto;

  constructor(
    private store: Store,
    private userService: UserService,
    private messageService: MessageService
  ) {
    this.handleVisibilityChange();
  }

  async loadUserUiDto() {
    this.userUiDto = await firstValueFrom(this.store.select(selectUserUiDto));
  }

  connect(serverUrl: string) {
    // Load user ui dto
    this.loadUserUiDto();

    this.socket = io(`wss://${serverUrl}`, {
      transports: ['websocket'], // Ensure WebSocket is the primary transport
    });

    this.socket.on('connect', () => {
      console.log('Connected to Socket.IO server');
      this.socket?.emit('register', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });

      // Start the heartbeat if it hasn't been started yet
      if (!this.heartbeatInterval) {
        this.startHeartbeat();
      }
    });

    this.socket.on('disconnect', (reason) => {
      console.log('Disconnected:', reason);
      this.socket?.emit('unregister', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });

      setTimeout(() => {
        if (!this.socket) this.connect(serverUrl);
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection Error:', error);
      setTimeout(() => {
        if (!this.socket) this.connect(serverUrl);
      }, this.reconnectInterval); // Reconnect on error
    });

    this.socket.on('reconnect_attempt', () => {
      console.log('Reconnecting...');
    });

    this.socket.on('redisDashboardCacheUpdates', (message) => {
      console.log(message);
      this.handleRedisCacheUpdates(JSON.parse(message));
    })

    this.socket.on('DSC_CHANNEL', (message) => {
      console.log(message);
      this.handleDscUpdates(JSON.parse(message));
    })

    this.socket.on('pong', (message) => {
      console.log(message);
    })
  }

  private startHeartbeat() {
    this.heartbeatInterval = setInterval(() => {
      if (this.socket && this.socket.connected) {
        this.socket.emit('ping', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId, timestamp: new Date().toLocaleString() }); // Send a ping message
      }
    }, 30000); // Send a ping every 30 seconds
  }

  private clearHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
  }

  private handleVisibilityChange() {
    document.addEventListener('visibilitychange', () => {
      if (!document.hidden && !this.socket?.connected) {
        console.log('Tab is active again, reconnecting socket...');
        this.socket?.connect();
      }
    });
  }

  private async handleRedisCacheUpdates(data: any) {
    if (data['cacheUpdateType'] == CacheUpdateTypes.ORG_WRAPPER) {
      const organizationWrapperUiDto = data as OrganizationWrapperUiDto;
      this.store.dispatch(OnboardingActions.updateOrganizationUiDto({ organizationWrapperUiDto }))
    }

    if (data['cacheUpdateType'] == CacheUpdateTypes.TENDER_WRAPPER) {
      const tenderWrapperUiDto = data as TenderWrapperUiDto;
      const oldTenderWrapperUiDto = await firstValueFrom(this.store.pipe(select(selectTenderWrapperUiDto)));
      if (tenderWrapperUiDto.tenderId == oldTenderWrapperUiDto?.tenderId) {
        this.store.dispatch(TenderManagementActions.setCurrentTenderWrapperUiDto({ tenderWrapperUiDto }))
      }

    }
  }

  private async handleDscUpdates(dscNotificationDto: DscNotificationDto) {
    let userUiDto = await firstValueFrom(this.store.pipe(select(selectUserUiDto)));

    if (userUiDto?.userId == dscNotificationDto.userId) {
      if (dscNotificationDto.actionType == DscNotificationEnum.DSC_CONNECTION_CONNECTED) {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'DSC connected successfully!' });
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_ADDED) {
        await this.userService.getUserDetails();
      } else if (dscNotificationDto.actionType == DscNotificationEnum.DSC_CONNECTION_DISCONNECTED) {
        this.messageService.add({ severity: 'error', summary: 'Failed', detail: 'Utility connection disconnected!' });
      }
    }
  }

  ngOnDestroy(): void {
    this.clearHeartbeat();
    if (this.socket) {
      this.socket.emit('unregister', { id: this.socket?.id, user: this.userUiDto?.primaryEmailId });
      this.socket.disconnect();
    }
  }
}
