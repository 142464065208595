import { EncryptionAndDecryptionService } from './encryption-and-decryption-service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, catchError, Observable, tap, Subscription, map, firstValueFrom } from 'rxjs';
import { ChangePasswordDto } from '../models/ChangePasswordDto';
import { ForgotPasswordDto } from '../models/ForgotPasswordDto';
import { ServerAPIResponseDto } from '../models/ServerAPIResponseDto';
import { SessionInfoDto } from '../models/SessionInfoDto';
import { RegistrationUserModalDto } from '../models/user/RegistrationUserModalDto';

import { ErrorService } from './error.service';
import { VerificationWorkflowWrapper } from '../models/VerificationWorkflowWrapper';
import { PreferenceCategoryDto } from '../models/user/PreferenceCategoryDto';
import { UserUiDto } from '../models/user/UserUiDto';
import { select, Store } from '@ngrx/store';
import { SessionActions } from '../state-management/session.actions';
import { selectSessionInfo } from '../state-management/session.features';
import { VerifyOtpWorkflowEnum } from '../enums/VerifyOtpWorkflowEnum';
import { UserInviteDto } from '../models/UserInviteDto';
import { AdminUserPrivilege } from '../enums/AdminUserPrivilege';
import { DscTransactionDto } from '../models/DscTransactionDto';
import { AuthenticationResponseDto } from '../models/AuthenticationResponseDto';
import { ApplicationConstants } from '../util/ApplicationConstants';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnDestroy {
  // _userUiDto$ = new BehaviorSubject<UserUiDto | null>(null);
  // private userUiDto?: UserUiDto;
  // private sessionInfoDto?: SessionInfoDto;

  _showLoginRegistrationButton$ = new BehaviorSubject<boolean>(false);
  private _sessionExpired$: Observable<boolean> = new Observable();

  sessionExpiredSubscription$?: Subscription;
  sessionSubscription$?: Subscription;

  otpWorkflow: VerifyOtpWorkflowEnum = VerifyOtpWorkflowEnum.SIMPLE_WORKFLOW;
  emailForOnboarding?: string;
  emailVerificationId?: string;

  constructor(
    private enAndDec: EncryptionAndDecryptionService,
    private http: HttpClient,
    private errorService: ErrorService,
    private store: Store
  ) {
    this.checkLoginButtonContextSensitivity();

    this._sessionExpired$ = this.errorService.getSessionExpired$;
    this.sessionExpiredSubscription$ = this._sessionExpired$.subscribe(data => {
      if (data && data == true) {
        this.clear();
      }
    })
  }

  get getLoginContextSensitivity$() { return this._showLoginRegistrationButton$.asObservable(); }
  get getOtpWorkflow() { return this.otpWorkflow; };
  get getEmailForOnboarding() { return this.emailForOnboarding; };
  get getEmailVerificationId() { return this.emailVerificationId; };

  setOtpWorkflow(newWorkflow: VerifyOtpWorkflowEnum) {
    this.otpWorkflow = newWorkflow;
  }

  setEmailForOnboarding(emailForOnboarding: string) {
    this.emailForOnboarding = emailForOnboarding;
  }

  setEmailVerificationId(emailVerificationId: string) {
    this.emailVerificationId = emailVerificationId;
  }

  checkLoginButtonContextSensitivity() {
    let auctionSessionId = localStorage.getItem('AUC_SESSION_ID');
    if (auctionSessionId) {
      this._showLoginRegistrationButton$.next(false);
    } else {
      this._showLoginRegistrationButton$.next(true);
    }
  }

  async getUserDetails() {
    let sessionId = localStorage.getItem("AUC_SESSION_ID");
    if (sessionId) {
      try {
        let apiResponseDto: ServerAPIResponseDto = await firstValueFrom(this.checkIfValidSession(sessionId));
        if (apiResponseDto && apiResponseDto.code == ApplicationConstants.SUCCESS_CODE) {
          let data = apiResponseDto.data as AuthenticationResponseDto;
          let sessionInfo = data.sessionInfoDto as SessionInfoDto;
          this.store.dispatch(SessionActions.saveSessionInfo({ sessionInfo }))
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  checkIfValidSession(sessionId: string) {
    const headers = new HttpHeaders().set('Authorization', "JSESSIONID=" + sessionId).
      set("Cache-Control", 'no-cache').
      set("Cache-control", 'no-store').
      set("Pragma", 'no-cache').
      set("Expires", '0');
    return this.http.get<string>('userDetails', { headers: headers }).pipe(
      catchError(this.errorService.returnNull<any>('User session is not valid ' + sessionId)))
  }

  registerUsers(
    registrationUserModalDto: RegistrationUserModalDto,
    emailId: string,
    password: string
  ): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + window.btoa(encodeURIComponent(emailId!) + ':' + password)
    });

    return this.http.post<ServerAPIResponseDto>('registerUsers', registrationUserModalDto, { headers })
      .pipe(
        tap(_ => console.log("User Registered Successfully : " + registrationUserModalDto.companyName)),
        catchError(this.errorService.handleError("Error while user registration : " + registrationUserModalDto.companyName)))
  }

  generateOTP(emailId: string, name: string, workflowName: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('name', name).set('workFlowName', workflowName);
    return this.http.get<ServerAPIResponseDto>('OTPS', { params }).pipe(
      tap(_ => console.log("OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  resendOTP(emailId: string, resentWorkflow: string, otpId?: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId!).set('resentWorkflow', resentWorkflow!);
    if (otpId) params.set('otpId', otpId);
    return this.http.get<ServerAPIResponseDto>('resendOTPS', { params }).pipe(
      tap(_ => console.log("Resend OTP Generate Successfully : " + emailId)),
      catchError(this.errorService.handleError("Error while generate : " + emailId)))
  }

  verifyOTP(otpId: string, userEnteredOtp: string): Observable<any> {
    let params = new HttpParams().set("otpId", otpId).set('userEnteredOtp', userEnteredOtp!);
    return this.http.get<ServerAPIResponseDto>('verifyEmail', { params }).pipe(
      tap(_ => console.log("OTP Verification Successfully : " + otpId)),
      catchError(this.errorService.handleError("Error while verification OTP : " + otpId)))
  }

  logout(email?: string): Observable<ServerAPIResponseDto> {
    let params = new HttpParams().set('emailId', email!);
    return this.http.get<any>('logout', { params }).pipe(
      tap(_ => console.log("logout successfully")),
      catchError(this.errorService.handleError<any>('Error while doing logout')))
  }

  updateUserDetails(userUiDto: UserUiDto): Observable<any> {
    let headers = new HttpHeaders()

    headers = this.enAndDec.setCustomEncryptionHeader(headers)
    let data = this.enAndDec.encryptObject(userUiDto);
    return this.http.post<any>('users', data, { headers }).pipe(
      tap(_ => console.log("Update Details Successfully for User" + userUiDto.firstName)),
      map(response => {
        return this.enAndDec.decryptObject(response);
      }),
      catchError(this.errorService.handleError<any>('Error while updating details for user' + userUiDto)))
  }

  forgotPassword(forgotPasswordDto: ForgotPasswordDto, email: string, password: string): Observable<any> {
    let headers = new HttpHeaders({ Authorization: 'Basic ' + window.btoa(encodeURIComponent(email) + ':' + password) });

    return this.http.post<ServerAPIResponseDto>('forgotPassword', forgotPasswordDto, { headers }).pipe(
      tap(_ => console.log("Forgot password successfully for User : " + email)),
      catchError(this.errorService.handleError("Error while forgot password for user : " + email)))
  }

  inviteUsers(userInviteDto: UserInviteDto): Observable<any> {
    return this.http.post<any>('onboard-internal-users', userInviteDto).pipe(
      tap(_ => console.log("Called onboard-internal-users api successfully" + userInviteDto)),
      map(response => {
        return this.enAndDec.decryptObject(response);
      }),
      catchError(this.errorService.handleError<any>('Error while calling onboard-internal-users api' + userInviteDto)))
  }

  removeUser(orgCode: string, companyCode: string, plantCode: string, emailId: string,): Observable<any> {
    return this.http.post<any>(`internal-users/removeUserMapping/${orgCode}/${companyCode}/${plantCode}/${emailId}`, '').pipe(
      tap(_ => console.log("API called successfully with: ")),
      catchError(this.errorService.handleError<any>('Error while calling the remove user API'))
    );
  }

  mapUsers(userInviteDto: UserInviteDto): Observable<any> {
    return this.http.post<any>('onboard-internal-users', userInviteDto).pipe(
      tap(_ => console.log("Called onboard-internal-users api successfully" + userInviteDto)),
      map(response => { response; }),
      catchError(this.errorService.handleError<any>('Error while calling onboard-internal-users api' + userInviteDto)))
  }

  makeOffer(auctionId: string, auctionHouseId: string, lotId: string, userId: string, offerPrice: number): Observable<any> {
    let params = new HttpParams().set('auctionId', auctionId).set('auctionHouseId', auctionHouseId).set('lotId', lotId).set('userId', userId).set('offerPrice', offerPrice);
    return this.http.post<ServerAPIResponseDto>('makeOffer', null, { params }).pipe(
      tap(_ => console.log("Offer Placed Successfully for User : " + userId)),
      catchError(this.errorService.handleError("Error while placing offer for user : " + userId)))
  }

  getUserByUserId(userId: string): Observable<any> {
    let params = new HttpParams().set("userId", userId);
    return this.http.get<ServerAPIResponseDto>('userByUserId', { params }).pipe(
      tap(_ => console.log("User Data Fetch Successfully : " + userId)),
      catchError(this.errorService.handleError("Error while fetching user data : " + userId)))
  }

  getUserDetailsWithSession(sessionId: string) {
    const headers = new HttpHeaders().set('Authorization', "JSESSIONID=" + sessionId).
      set("Cache-Control", 'no-cache').
      set("Cache-control", 'no-store').
      set("Pragma", 'no-cache').
      set("Expires", '0');
    return this.http.get<string>('userDetails', { headers: headers }).pipe(
      tap(_ => console.log("got data for user by sessionId")),
      catchError(this.errorService.handleError<any>('Error while getting data for user by sessionId' + sessionId)))
  }

  generateOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper: VerificationWorkflowWrapper): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('generateOTPEmailMobileVerificationWorkflow', verificationWorkflowWrapper).pipe(
      tap(_ => console.log("OTP Generate Successfully : " + verificationWorkflowWrapper.newEmailId)),
      catchError(this.errorService.handleError("Error while generate : " + verificationWorkflowWrapper.newEmailId)))
  }

  verifyOTPEmailMobileVerificationWorkflow(verificationWorkflowWrapper: VerificationWorkflowWrapper): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('verifyOTPEmailMobileVerificationWorkflow', verificationWorkflowWrapper).pipe(
      tap(_ => console.log("OTP Verify Successfully : " + verificationWorkflowWrapper.newEmailId)),
      catchError(this.errorService.handleError("Error while verify : " + verificationWorkflowWrapper.newEmailId)))
  }

  subcategoryPreference(categoryIds: PreferenceCategoryDto[]): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('subcategoryPreference', categoryIds).pipe(
      tap(_ => console.log("Categories preference saved successfully : " + categoryIds.length)),
      catchError(this.errorService.handleError("Error while saving categories preference : " + categoryIds.length)))
  }

  sendAddDscEvent(dscTransactionDto: DscTransactionDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('dsc/transaction', dscTransactionDto).pipe(
      tap(_ => console.log("Add DSC event send successfully : " + dscTransactionDto.userId)),
      catchError(this.errorService.handleError("Error while sending DSC event : " + dscTransactionDto.userId)))
  }
  sendDeleteDscEvent(dscTransactionDto: DscTransactionDto): Observable<any> {
    return this.http.post<ServerAPIResponseDto>('dsc/transaction', dscTransactionDto).pipe(
      tap(_ => console.log("Delete DSC event send successfully : " + dscTransactionDto.userId)),
      catchError(this.errorService.handleError("Error while sending DSC event : " + dscTransactionDto.userId)));
  }

  sendResetPasswordLink(emailId: string): Observable<any> {
    let params = new HttpParams().set('emailId', emailId);
    return this.http.get<ServerAPIResponseDto>('manage-user-password', { params }).pipe(
      tap(_ => console.log("Reset password successfully for User : " + emailId)),
      catchError(this.errorService.handleError("Error while forgot password for user : " + emailId)))
  }

  checkOnboardEmail(emailVerificationId: string): Observable<any> {
    let params = new HttpParams().set('emailVerificationId', emailVerificationId);
    return this.http.get<ServerAPIResponseDto>('check-onboard-email-invitation', { params }).pipe(
      tap(_ => console.log("EmailVerificationId checked : " + emailVerificationId)),
      catchError(this.errorService.handleError("Error while checking emailVerificationId : " + emailVerificationId)))
  }

  changePassword(emailVerificationId: string, password: string): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Basic ' + window.btoa(encodeURIComponent(emailVerificationId!) + ':' + password)
    });

    return this.http.post<ServerAPIResponseDto>('onboard-reset-password', null, { headers }).pipe(
      tap(_ => console.log("Password Reset Successfully for User : " + emailVerificationId)),
      catchError(this.errorService.handleError("Error while resetting password for user : " + emailVerificationId)))
  }

  clear() {
    this._showLoginRegistrationButton$.next(true);
    this.store.dispatch(SessionActions.clearSession());
  }


  ngOnDestroy(): void {
    if (this.sessionExpiredSubscription$) {
      this.sessionExpiredSubscription$.unsubscribe()
    }
    if (this.sessionSubscription$) {
      this.sessionSubscription$.unsubscribe()
    }
  }
}
