import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BasicAuthHttpInterceptor } from './shared/interceptors/basic-auth-http.interceptor';
import { environment } from 'src/environments/environment';
import { provideFirebaseApp, initializeApp, FirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from "@angular/fire/firestore";
import { getAnalytics, provideAnalytics } from "@angular/fire/analytics";
import { getPerformance, initializePerformance, providePerformance } from "@angular/fire/performance";
import { NgxEchartsModule } from 'ngx-echarts';
import { TimeAgoPipe } from './shared/pipes/time-ago.pipe';
import { FlatpickrModule } from 'angularx-flatpickr';
import { icons, LucideAngularModule } from 'lucide-angular';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { StoreModule } from '@ngrx/store';
import { sessionFeature } from './shared/state-management/session.features';
import { navigationFeature } from './shared/state-management/navigation/navigation.features';
import { onboardingFeature } from './shared/state-management/onboarding/onboarding.features';
import { tenderManagementFeature } from './shared/state-management/tender/tender.features';
import { OrdinalDatePipe } from './shared/pipes/ordinal-date.pipe';
import { ConfirmationService, MessageService } from 'primeng/api';
import { PriceFormatPipe } from './shared/pipes/price-format.pipe';
import { TitleCasePipe } from './shared/pipes/title-case.pipe';
import { SuperadminCategoryComponent } from './layouts/sadmin/superadmin-category/superadmin-category.component';


@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    SuperadminCategoryComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ToastModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    FlatpickrModule.forRoot(),
    LucideAngularModule.pick(icons),
    StoreModule.forRoot({}),
    StoreModule.forFeature(sessionFeature),
    StoreModule.forFeature(navigationFeature),
    StoreModule.forFeature(onboardingFeature),
    StoreModule.forFeature(tenderManagementFeature),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BasicAuthHttpInterceptor, multi: true,
    },
    NgbActiveModal, DecimalPipe, DatePipe, TimeAgoPipe, DialogService, DynamicDialogRef, OrdinalDatePipe, PriceFormatPipe,
    ConfirmationService, TitleCasePipe, MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(
    firebaseApp: FirebaseApp
  ) {
    initializePerformance(firebaseApp);
  }
}
